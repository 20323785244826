import React, {useEffect} from 'react';

export default function TermsConditions() {
    const collegeBanner = require('../../resources/css/images/college-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - Terms and Conditions';
    }, []);

    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">

                        <article>
                            <header>
                                <h2>Executive Storage Suites Terms &amp; Conditions</h2>
                            </header>

                            <span className="image featured"><img src={String(collegeBanner)} alt="" /></span>

                            <p><b>Executive Storage Suites</b> and its affiliates provide their services to you subject to the following notices, terms, and conditions.</p>

                            <p><b>Promotions and Offers</b></p>

                            <p>*Subject to change.. Promotions good for new customers only. Subject to specific units and availability.. Other restrictions, taxes, fees, and administrative fee, apply. See contract for full details. Not available on transfers or additional spaces. Pricing subject to change. Reservation required to guarantee price. Actual unit sizes may vary from approximate size estimate. Please inspect any unit before renting. Online pricing available only for online reservations and rentals.</p>

                            <p><b>Refunds</b></p>

                            <p>All rental transactions are final. If you rent a storage unit/space, you are not entitled to a refund for any unused portion of the rental period for which you have paid, including prepaid rent. Customers may rent on a monthly basis. The rental period starts on the day the lease is signed and continues up to the anniversary date of the following month. Rent will not be prorated for move-outs. In the event your account is overcharged due to an error, we will refund your account after being notified of the discrepancy.</p>

                            <p>For Merchandise Sales we do not except refunds. ALL SALES ARE FINAL.</p>

                            <p><b>Privacy Policy</b></p>

                            <p><b>Executive Storage Suites</b> respects the privacy of its users. Please read the complete text of our privacy policy.</p>

                            <p><b>Disclaimer</b></p>

                            <p>This site is provided by <b>Executive Storage Suites</b> on an “as is” basis. <b>Executive Storage Suites</b> makes no representations or warranties of any kind, express or implied, as to the operation of the site or the information, content, materials, or products included on this site. <b>Executive Storage Suites</b> disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. <b>Executive Storage Suites</b> will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

                            <p><b>Indemnification</b></p>

                            <p>You agree to defend, indemnify and hold harmless <b>Executive Storage Suites</b>, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including legal fees, arising out of your use of the website and services, including but not limited to out of your violation of any representation or warranty contained in these Terms and Conditions.</p>

                        </article>
                    </div>
                </div>
            </section>
        </div>
    );
}
