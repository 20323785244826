import React, {useEffect, useMemo, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import {PaymentInputsWrapper, usePaymentInputs} from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {Button} from 'reactstrap';
import SubscriptionApiService from '../../../api/SubscriptionApiService';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import SubscriptionDTO from '../../../DTOs/Subscription/SubscriptionDTO';
import CardPaymentDto from '../../../DTOs/Subscription/CardPaymentDto';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ChangePaymentModal(props) {
    const { open, userId, subscription, setSnackbarProperties, close, isMobile } = props;

    const[currentPaymentOptions, setCurrentPaymentOptions] = useState([]);
    const[currentSelectedPaymentOption, setCurrentSelectedPaymentOption] = useState();
    const[cardNumber, setCardNumber] = useState('');
    const[expirationDate, setExpirationDate] = useState();
    const[cvc, setCvc] = useState();
    const[loading, setLoading] = useState(false);

    const newPaymentMethodRadioValue = '0';

    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
    } = usePaymentInputs();

    const isNewPaymentMethodSelected = useMemo(() => currentSelectedPaymentOption === parseInt(newPaymentMethodRadioValue), [currentSelectedPaymentOption]);

    const subscriptionApiService = new SubscriptionApiService();

    async function savePaymentInfo() {
        const methodToSave = currentPaymentOptions.find((value) => value.paymentProfileId === currentSelectedPaymentOption.toString());
        if(isNewPaymentMethodSelected) {
            const newPaymentCard = new CardPaymentDto();
            newPaymentCard.cardNumber = cardNumber;
            newPaymentCard.cardExpiration = expirationDate;
            newPaymentCard.cvv = cvc;
            const addResponse = await subscriptionApiService.addPaymentMethod(subscription.id, newPaymentCard);

            if(addResponse.success) {
                setSnackbarProperties('Successfully added payment method');
                closeModal();
            } else {
                setSnackbarProperties(`Error adding payment method: ${addResponse.message}`, 'error');
            }
        } else {
            const updateResponse = await subscriptionApiService.updatePaymentMethod(subscription.id, methodToSave.paymentProfileId);
            if(updateResponse.success) {
                setSnackbarProperties('Successfully updated payment method');
                closeModal();
            } else {
                setSnackbarProperties(`Error updating payment method: ${updateResponse.message}`, 'error');
            }
        }

    }

    function handleCardNumberChange(event) {
        setCardNumber(event.target.value);
    }

    function handleExpirationDateChange(event) {
        setExpirationDate(event.target.value);
    }

    function handleCVCChange(event) {
        setCvc(event.target.value);
    }

    function closeModal() {
        setCardNumber('');
        setExpirationDate('');
        setCvc('');
        close();
    }

    async function getUserPaymentInfo() {
        const methods = await subscriptionApiService.getUserPaymentMethods(userId);
        setCurrentPaymentOptions(methods.data);
    }

    useEffect(() => {
        if(open) {
            setLoading(true);
            getUserPaymentInfo().then(() => {
                setCurrentSelectedPaymentOption(parseInt(subscription.paymentProfileId));
                setLoading(false);
            });
        }

    }, [open]);

    return (
        <div>
            { loading ? <Backdrop open={loading}><CircularProgress color={'inherit'}/></Backdrop> :
                <Dialog open={open}>
                    <DialogTitle>Change Payment Method</DialogTitle>
                    <DialogContent>
                        <RadioGroup
                            value={currentSelectedPaymentOption}
                            onChange={(event) => setCurrentSelectedPaymentOption(parseInt(event.target.value))}
                        >
                            {currentPaymentOptions.map((paymentOption, index) =>
                                <FormControlLabel className={paymentOption.isExpired ? 'expired-card-option' : ''} key={index} value={parseInt(paymentOption.paymentProfileId)}
                                    control={<Radio
                                        checked={currentSelectedPaymentOption === parseInt(paymentOption.paymentProfileId)}
                                        disabled={paymentOption.isExpired}
                                    />}
                                    label={`${paymentOption.cardType} - ${paymentOption.cardNumber} ${paymentOption.isExpired ? '- EXPIRED' : '' }`}/>
                            )}
                            <FormControlLabel control={<Radio/>} label={'New payment option'}
                                value={newPaymentMethodRadioValue}/>
                            {isNewPaymentMethodSelected && !isMobile && <PaymentInputsWrapper
                                className={currentSelectedPaymentOption === newPaymentMethodRadioValue ? 'change-payment-card-input-hidden' : 'change-payment-card-input-shown'}
                                {...wrapperProps}
                            >
                                {cardNumber.length > 0 && <svg {...getCardImageProps({images})} /> }
                                <input {...getCardNumberProps({onChange: handleCardNumberChange})} />
                                <input {...getExpiryDateProps({onChange: handleExpirationDateChange})} />
                                <input {...getCVCProps({onChange: handleCVCChange})} />
                            </PaymentInputsWrapper>}
                            {isNewPaymentMethodSelected && isMobile && <div>
                                {cardNumber.length > 0 && <svg className={'mobile-card-image'} {...getCardImageProps({images})} />}
                                <input className={'mobile-card-number-input'} {...getCardNumberProps({onChange: handleCardNumberChange})} value={cardNumber} />
                                <input className={'mobile-exp-date-input'} {...getExpiryDateProps({onChange: handleExpirationDateChange})} value={expirationDate} />
                                <input className={'mobile-cvc-input'} {...getCVCProps({onChange: handleCVCChange})} value={cvc}/>
                            </div>}
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button className={'mobile-modal-button'} onClick={closeModal}>Cancel</Button>
                        <Button className={'mobile-modal-button'} color={'primary'} onClick={savePaymentInfo}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    );
}

ChangePaymentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
    subscription: PropTypes.objectOf(SubscriptionDTO).isRequired,
    setSnackbarProperties: PropTypes.func,
    close: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
};
