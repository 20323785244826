export default class CardPaymentDto {
    /**
     * @type{string}
     */
    cardNumber;

    /**
     * @type{string}
     */
    cardExpiration;

    /**
     * @type{string}
     */
    cvv;

    /**
     * @type{number}
     */
    paymentFrequency;
}

export const PaymentFrequency = {
    Monthly: 1,
    BiMonthly: 2,
    Quarterly: 3,
    SemiAnnual: 4,
    Annual: 5,
};
