import React from 'react';
import PropTypes from 'prop-types';
import StorageUnitMaintenanceDTO from '../../../DTOs/StorageUnit/StorageUnitMaintenanceDTO';
import AppUserGenericDTO from '../../../DTOs/User/AppUserGenericDTO';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {Button, Label} from 'reactstrap';
import {Select} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export default function ManualAssignStorageUnit(props) {
    const {open, storageUnit, users, isMobile, handleAssign, selectedUser, setSelectedUser, close} = props;

    function handleSelectedUserChange(event) {
        setSelectedUser(event.target.value);
    }

    return(
        <Dialog open={open} >
            <DialogTitle>Manually Assign Storage Unit {storageUnit?.name}</DialogTitle>
            <DialogContent>
                <Label>User to Assign:</Label>
                <Select
                    sx={{width: '100%'}}
                    value={selectedUser}
                    onChange={handleSelectedUserChange}
                >
                    {users.sort((a, b) => a.firstName > b.firstName).map((user, index) => {
                        return <MenuItem key={index} value={user.id}>{`${user.firstName} ${user.lastName} (${user.username})`}</MenuItem>;
                    })}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button className={isMobile ? 'mobile-modal-button' : ''} autoFocus variant='contained' onClick={close}>
                    Cancel
                </Button>
                <Button className={isMobile ? 'mobile-modal-button' : ''} color={'danger'} onClick={handleAssign}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

ManualAssignStorageUnit.propTypes = {
    open: PropTypes.bool.isRequired,
    storageUnit: PropTypes.objectOf(StorageUnitMaintenanceDTO).isRequired,
    users: PropTypes.arrayOf(AppUserGenericDTO).isRequired,
    isMobile: PropTypes.bool.isRequired,
    handleAssign: PropTypes.func.isRequired,
    selectedUser: PropTypes.objectOf(AppUserGenericDTO).isRequired,
    setSelectedUser: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};
