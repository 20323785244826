/**
 * @class
 */
export default class AppUserGenericDTO {

    /**
     * @type {number}
     */
    id;

    /**
     * @type {string}
     */
    username;

    /**
     * @type {string}
     */
    firstName;

    /**
     * @type {string}
     */
    lastName;

    /**
     * @type {string}
     */
    phone;

    /**
     * @type {string}
     */
    address1;

    /**
     * @type {string=}
     */
    address2;

    /**
     * @type {string}
     */
    city;

    /**
     * @type {string}
     */
    state;

    /**
     * @type {string}
     */
    zip;

    /**
     * @type {string}
     */
    driversLicenseNumber;

    /**
     * @type {string}
     */
    licenseState;

    /**
     * @type {string=}
     */
    doorCode;

    constructor() {
        this.id = 0;
        this.username = '';
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.address1 = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.driversLicenseNumber = '';
        this.licenseState = '';
    }
}
