import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, TextField} from '@mui/material';
import authService from '../../Auth/AuthService';
import PasswordResetSubmissionDTO from '../../DTOs/User/PasswordResetSubmissionDTO';
import {useNavigate, useParams} from 'react-router-dom';
import authenticationManager from '../../Auth/AuthenticationManager';

export default function PasswordResetPage(props) {
    const {setSnackbarProperties, isMobile, isTablet} = props;
    const params = useParams();
    const token = params.token;

    const[password, setPassword] = useState('');
    const[passwordError, setPasswordError] = useState(false);
    const[confirmPassword, setConfirmPassword] = useState('');
    const[confirmPasswordError, setConfirmPasswordError] = useState(false);

    const navigate = useNavigate();

    const desktopFieldSx = {marginBottom: '20px'};
    const mobileFieldSx = {marginBottom: '20px', width: '100%'};

    function validate() {
        if(password.length < 6) {
            setSnackbarProperties('Password must be at least 6 characters long', 'error');
            setPasswordError(true);
            return false;
        }

        if(password !== confirmPassword) {
            setSnackbarProperties('Passwords must match', 'error');
            setConfirmPasswordError(true);
            return false;
        }

        return true;
    }

    async function resetPassword() {
        if(validate()) {
            const resetSubmission = new PasswordResetSubmissionDTO();

            resetSubmission.password = password;
            resetSubmission.token = token.toString();
            const resetResponse = await authService.submitChangedPassword(resetSubmission);

            if(resetResponse.success) {
                setSnackbarProperties('Successfully changed password');
                navigate('/');
            }
        }
    }

    useEffect(() => {
        if(authenticationManager.isAuthorized) {
            navigate('/user-info');
        }

        document.title = 'Executive Storage Suites - Reset Password';
    }, []);

    return(
        <Box>
            <h1>Reset Your Password</h1>
            <br/>
            <TextField
                type={'password'}
                sx={isMobile || isTablet ? mobileFieldSx : desktopFieldSx}
                label={'New Password'}
                error={passwordError}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <br/>
            <TextField
                type={'password'}
                sx={isMobile || isTablet ? mobileFieldSx : desktopFieldSx}
                label={'Confirm New Password'}
                error={confirmPasswordError}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
            />
            <br/>
            <Button variant={'contained'} onClick={resetPassword}>Submit</Button>
        </Box>
    );
}

PasswordResetPage.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
};
