import apiService from './ApiService';

export default class NavigationItemApiService {

    /**
     * Gets navigation items
     * @return {Promise<ServiceResponse<NavigationItemDto>>}
     * @constructor
     */
    async GetNavigationItems() {
        const navigationItems = await apiService.getData('api/Navigation');

        return navigationItems.data;
    }
}
