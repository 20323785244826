import React from 'react';
import PropTypes from 'prop-types';
import PendingApprovalDTO from '../../DTOs/Subscription/PendingApprovalDTO';
import {Box} from '@mui/material';
import {Button, Label} from 'reactstrap';
import {USDollar} from '../../utilities/Constants';

export default function PendingApprovalsCardMobile(props) {
    const {key, pendingApproval, setShowApproveDialog, setShowDenyDialog} = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`User: ${pendingApproval.appUser.firstName} ${pendingApproval.appUser.lastName} (${pendingApproval.appUser.username})`}</Label>
            <Label>{`Phone #: ${pendingApproval.appUser.phone}`}</Label>
            <Label>{`Driver's License #: ${pendingApproval.appUser.driversLicenseNumber}`}</Label>
            <Label>{`Storage Unit: ${pendingApproval.storageUnit.name}`}</Label>
            <Label>{`Size: ${pendingApproval.storageUnit.storageSize}`}</Label>
            <Label>{`Price: ${USDollar.format(pendingApproval.storageUnit.price)}`}</Label>
            <Button className={'mobile-button'} onClick={setShowApproveDialog}>Approve</Button>
            <Button className={'mobile-button'} color={'danger'} onClick={setShowDenyDialog}>Deny</Button>
        </Box>
    );
}

PendingApprovalsCardMobile.propTypes = {
    key: PropTypes.number,
    pendingApproval: PropTypes.objectOf(PendingApprovalDTO).isRequired,
    setShowApproveDialog: PropTypes.func.isRequired,
    setShowDenyDialog: PropTypes.func.isRequired,
};
