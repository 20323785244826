import React from 'react';
import {Box} from '@mui/material';
import {Button, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import StorageUnitDTO from '../../DTOs/StorageUnit/StorageUnitDTO';
import * as Constants from '../../utilities/Constants';

export default function StorageUnitCardMobile(props) {
    const { key, storageUnit, isOwned, isRequested, setOpenCancelUnit, setOpenEditPayment, setOpenReserveUnit, setOpenCancelRequest } = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`Storage Unit: ${storageUnit.name}`}</Label>
            <Label>{`Size: ${storageUnit.storageSize}`}</Label>
            <Label>{`Type: ${storageUnit.storageUnitType.name}`}</Label>
            <Label>{`Climate Controlled: ${storageUnit.climateControlled ? 'Yes' : 'No'}`}</Label>
            <Label>{`Price: ${Constants.USDollar.format(storageUnit.price)}`}</Label>
            { !isOwned && <Label>{`Available Date: ${storageUnit.nextReadyDate ? storageUnit.nextReadyDate.toString().substring(0, storageUnit.nextReadyDate.toString().length - 9) : 'Now'}`}</Label> }
            { !isOwned && !isRequested && <Button className={'mobile-button'} onClick={() => setOpenReserveUnit(true)}>Reserve</Button> }
            { !isOwned && isRequested && <Button className={'mobile-button'} color={'warning'} onClick={() => setOpenCancelRequest(true)}>Cancel Request</Button>}
            { isOwned && <Button className={'mobile-button'} onClick={setOpenEditPayment}>Edit Payment</Button> }
            { isOwned && <Button className={'mobile-button'} color={storageUnit.nextReadyDate ? 'none' : 'danger'} disabled={storageUnit.nextReadyDate} onClick={setOpenCancelUnit}>{storageUnit.nextReadyDate ? 'Already Cancelled' : 'Cancel Storage Unit'}</Button>}

        </Box>
    );
}

StorageUnitCardMobile.propTypes = {
    key: PropTypes.number.isRequired,
    storageUnit: PropTypes.objectOf(StorageUnitDTO).isRequired,
    isOwned: PropTypes.bool.isRequired,
    setOpenCancelUnit: PropTypes.func,
    setOpenEditPayment: PropTypes.func,
    setOpenReserveUnit: PropTypes.func,
    isRequested: PropTypes.bool,
    setOpenCancelRequest: PropTypes.func,
};
