export default class PasswordResetSubmissionDTO {
    /**
     * @type{string}
     */
    token;

    /**
     * @type{string}
     */
    password;
}
