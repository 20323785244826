/**
 * @class
 */
export class AppUserDTO {

    /**
     * @type {number}
     */
    Id;

    /**
     * @type {string}
     */
    FirstName;

    /**
     * @type {string}
     */
    LastName;

    /**
     * @type {string}
     */
    Phone;

    /**
     * @type {string}
     */
    Address1;

    /**
     * @type {?string}
     */
    Address2;

    /**
     * @type {string}
     */
    City;

    /**
     * @type {string}
     */
    State;

    /**
     * @type {string}
     */
    Zip;

    /**
     * @type {?string}
     */
    Comments;

    /**
     * @type {string}
     */
    DriversLicenseNumber;

    /**
     * @type {string}
     */
    LicenseState;

    /**
     * @type {?string}
     */
    DoorCode;

    /**
     * @type {boolean}
     */
    ForcePasswordReset;

    /**
     * @type {?string}
     */
    CustomerProfileId;

    /**
     * @type {?string}
     */
    Password;

    /**
     * @type {string}
     */
    Username;

    constructor() {
        this.Id = 0;
        this.FirstName = '';
        this.LastName = '';
        this.Phone = '';
        this.Address1 = '';
        this.Address2 = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Password = '';
        this.DriversLicenseNumber = '';
        this.LicenseState = '';
        this.Username = '';
    }
}
