import React, {useEffect, useMemo, useState} from 'react';

const items = [{id: 1, name:'Chair overstuffed', volume:'25', amount: 0},
    {id: 2, name:'Sofa love seat', volume:'35', amount: 0},
    {id: 3, name:'Sofa 3 cushion', volume:'65', amount: 0},
    {id: 4, name:'Sofa 4 cushion', volume:'65', amount: 0},
    {id: 5, name:'Sofa sectional pieces', volume:'40', amount: 0},
    {id: 6, name:'Dresser', volume:'30', amount: 0},
    {id: 7, name:'Mattress', volume:'65', amount: 0},
    {id: 8, name:'Dining chairs', volume:'5', amount: 0},
    {id: 9, name:'Dining table', volume:'30', amount: 0},
    {id: 10, name:'Dishwasher', volume:'20', amount: 0},
    {id: 11, name:'Dryer', volume:'25', amount: 0},
    {id: 12, name:'Refrigerator under 15', volume:'45', amount: 0},
    {id: 13, name:'Refrigerator over 16', volume:'65', amount: 0},
    {id: 14, name:'Stove', volume:'18', amount: 0},
    {id: 15, name:'Washing machine', volume:'25', amount: 0},
    {id: 16, name:'Bicycle', volume:'6', amount: 0},
    {id: 17, name:'Boxes', volume:'2.5', amount: 0},
    {id: 18, name:'Patio chairs', volume:'6', amount: 0},
    {id: 19, name:'Patio table', volume:'15', amount: 0}];



export default function SpaceCalculator() {
    const calculatorBanner = require('../../resources/css/images/calculator-banner.jpg');
    const gif25 = require('../../resources/css/images/25.gif');
    const gif50 = require('../../resources/css/images/50.gif');
    const gif75 = require('../../resources/css/images/75.gif');
    const gif100 = require('../../resources/css/images/100.gif');
    const gif150 = require('../../resources/css/images/150.gif');
    const gif200 = require('../../resources/css/images/200.gif');
    const gif250 = require('../../resources/css/images/250.gif');
    const gif300 = require('../../resources/css/images/300.gif');

    const[currentItems, setCurrentItems] = useState(items);
    const[componentKey, setComponentKey] = useState(0);

    const calculation = useMemo(() => {
        const volume = currentItems.reduce((accumulator, currentItem) => {
            return accumulator + (parseInt(currentItem.volume) * currentItem.amount);
        }, 0);
        return Math.round(volume * 1.15 / 6);
    }, [componentKey]);

    const recommended = useMemo(() => {
        const result = Math.floor((calculation + 24) / 25) * 25;

        if(result === 125) {
            return 150;
        }
        if(result === 150) {
            return 200;
        }
        if(result === 200) {
            return 250;
        }
        if(result === 250) {
            return 300;
        }
        return result;
    },[calculation]);

    const showRecommended = useMemo(() => {
        return recommended > 0 && recommended <= 300;
    }, [recommended]);

    const showRecommendedErrorExcess = useMemo(() => {
        return recommended > 300;
    }, [recommended]);

    const showRecommendedErrorZero = useMemo(() => recommended <= 0, [recommended]);

    function changeItemAmount(itemId, amount) {
        let itemAmount = amount;
        if(amount < 0) {
            itemAmount = 0;
        }
        const itemCopy = [...currentItems];
        setComponentKey(componentKey === 100 ? 0 : componentKey + 1);

        const index = itemCopy.findIndex((item) => item.id === itemId);
        itemCopy[index].amount = itemAmount;

        setCurrentItems(itemCopy);
    }

    useEffect(() => {
        document.title = 'Executive Storage Suites - Space Calculator';
    }, []);

    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">
                        <article>
                            <header>
                                <h2>Space Calculator</h2>
                            </header>
                            <span className="image featured"><img alt="" src={String(calculatorBanner)} /></span>

                            <p>Our online calculator provides an estimate of what size storage room you will need to store your items. Just fill in how many of each item you have, and the bottom of the page will automatically estimate!</p>

                            <form className="calc-form" id="space_calculator">
                                <h3>Living Room</h3>

                                <div className="row">
                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_1">Chair (overstuffed)</label> <input id="calc_1" name="chair_overstuffed" placeholder="0" type="number" value={currentItems.find((item) => item.id === 1)?.amount ?? undefined } onChange={(event) => changeItemAmount(1, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_2">Sofa (Love seat)</label> <input id="calc_2" name="sofa_loveseat" placeholder="0" type="number" value={currentItems.find((item) => item.id === 2)?.amount ?? undefined } onChange={(event) => changeItemAmount(2, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_3">Sofa (3 cushion)</label> <input id="calc_3" name="sofa_3" placeholder="0" type="number" value={currentItems.find((item) => item.id === 3)?.amount ?? undefined } onChange={(event) => changeItemAmount(3, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_5">Sofa Sectional pc.</label> <input id="calc_5" name="sofa_sectional" placeholder="0" type="number" value={currentItems.find((item) => item.id === 5)?.amount ?? undefined } onChange={(event) => changeItemAmount(5, event.target.value)} /></div>
                                </div>

                                <p></p>

                                <h3>Bedroom</h3>

                                <div className="row">
                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_6">Dresser</label> <input id="calc_6" name="dresser" placeholder="0" type="number" value={currentItems.find((item) => item.id === 6)?.amount ?? undefined } onChange={(event) => changeItemAmount(6, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_7">Mattress</label> <input id="calc_7" name="mattress" placeholder="0" type="number" value={currentItems.find((item) => item.id === 7)?.amount ?? undefined } onChange={(event) => changeItemAmount(7, event.target.value)} /></div>
                                </div>

                                <p></p>

                                <h3>Dining Room</h3>

                                <div className="row">
                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_8">Dining Chair</label> <input id="calc_8" name="dining_chair" placeholder="0" type="number" value={currentItems.find((item) => item.id === 8)?.amount ?? undefined } onChange={(event) => changeItemAmount(8, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_9">Dining Table</label> <input id="calc_9" name="dining_table" placeholder="0" type="number" value={currentItems.find((item) => item.id === 9)?.amount ?? undefined } onChange={(event) => changeItemAmount(9, event.target.value)} /></div>
                                </div>

                                <p></p>

                                <h3>Kitchen &amp; Appliances</h3>

                                <div className="row">
                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_10">Dishwasher</label> <input id="calc_10" name="dishwasher" placeholder="0" type="number" value={currentItems.find((item) => item.id === 10)?.amount ?? undefined } onChange={(event) => changeItemAmount(10, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_11">Dryer</label> <input id="calc_11" name="dryer" placeholder="0" type="number" value={currentItems.find((item) => item.id === 11)?.amount ?? undefined } onChange={(event) => changeItemAmount(11, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_12">Refrigerator (&lt; 15 cu. ft.)</label> <input id="calc_12" name="refridgerator_15" placeholder="0" type="number" value={currentItems.find((item) => item.id === 12)?.amount ?? undefined } onChange={(event) => changeItemAmount(12, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_13">Refrigerator (&gt; 16 cu. ft.)</label> <input id="calc_13" name="refridgerator_16" placeholder="0" type="number" value={currentItems.find((item) => item.id === 13)?.amount ?? undefined } onChange={(event) => changeItemAmount(13, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_15">Stove</label> <input id="calc_14" name="stove" placeholder="0" type="number" value={currentItems.find((item) => item.id === 14)?.amount ?? undefined } onChange={(event) => changeItemAmount(14, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_15">Washing Machine</label> <input id="calc_15" name="washing_machine" placeholder="0" type="number" value={currentItems.find((item) => item.id === 15)?.amount ?? undefined } onChange={(event) => changeItemAmount(15, event.target.value)} /></div>
                                </div>

                                <p></p>

                                <h3>Miscellaneous Items</h3>

                                <div className="row">
                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_16">Bicycle</label> <input id="calc_16" name="bicycle" placeholder="0" type="number" value={currentItems.find((item) => item.id === 16)?.amount ?? undefined } onChange={(event) => changeItemAmount(16, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_17">Boxes</label> <input id="calc_17" name="boxes" placeholder="0" type="number" value={currentItems.find((item) => item.id === 17)?.amount ?? undefined } onChange={(event) => changeItemAmount(17, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_18">Patio Chair</label> <input id="calc_18" name="patio_chair" placeholder="0" type="number" value={currentItems.find((item) => item.id === 18)?.amount ?? undefined } onChange={(event) => changeItemAmount(18, event.target.value)} /></div>

                                    <div className="col-xs-6 col-sm-4 col-md-2"><label htmlFor="calc_19">Patio Table</label> <input id="calc_19" name="patio_table" placeholder="0" type="number" value={currentItems.find((item) => item.id === 19)?.amount ?? undefined } onChange={(event) => changeItemAmount(19, event.target.value)} /></div>
                                </div>
                            </form>
                            <br/><br/>
                            {showRecommended &&

                                <div className="recommendation">
                                    <h3>Your recommended size is: </h3>

                                    <p className="final-rec"><span id="final_recommendation">{recommended}</span> sq. ft.</p>
                                    <label>Unit information:</label>

                                    <table className="rec-table">
                                        <thead>
                                            <tr>
                                                <th>Unit size</th>
                                                <th>How to visualize</th>
                                                <th>What unit will hold</th>
                                                <th>Unit visualization</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {recommended === 25 &&
                                            <tr className="tr-25">
                                                <td data-label="Unit size">5&apos; x 5&apos;<br/>
                                                25 sq. ft.<br/>
                                                (1.52m x 1.52m)<br/>
                                                (2.32 sq. m.)</td>
                                                <td data-label="How to visualize">4&apos;x8&apos; Trailer<br/>
                                                (1.22m x 2.44m Trailer)<br/>
                                                Small closet<br/>
                                                Half bathroom</td>
                                                <td data-label="What unit will hold">Chest of drawers or desk mattress set, several boxes and small items OR about 50 file boxes</td>
                                                <td data-label="Unit visualization"><img src={String(gif25)} alt={''} /></td>
                                            </tr>
                                            }
                                            {recommended === 50 &&
                                            <tr className="tr-50">
                                                <td data-label="Unit size">5&apos; x 10&apos;<br/>
                                                50 sq. ft.<br/>
                                                (1.52m x 3.05m)<br/>
                                                (4.65 sq. m.)</td>
                                                <td data-label="How to visualize">6&apos;x12&apos; Trailer<br/>
                                                (1.83m x 3.66m Trailer)<br/>
                                                Pickup Truck or Cargo Van<br/>
                                                Walk-in closet</td>
                                                <td data-label="What unit will hold">Mattress set, sofa, dining room set or chest of drawers, boxes and small items</td>
                                                <td data-label="Unit visualization"><img src={String(gif50)} alt={''} /></td>
                                            </tr>
                                            }
                                            { recommended === 75 &&
                                            <tr className="tr-75">
                                                <td data-label="Unit size">5&apos; x 15&apos;<br/>
                                                75 sq. ft.<br/>
                                                (1.52m x 4.57m)<br/>
                                                (6.97 sq. m.)</td>
                                                <td data-label="How to visualize">Large walk-in closet<br/>
                                                Small bedroom</td>
                                                <td data-label="What unit will hold">Standard furnishings of one bedroom apartment without appliances, or about 150 file boxes</td>
                                                <td data-label="Unit visualization"><img src={String(gif75)} alt={''} /></td>
                                            </tr>
                                            }
                                            { recommended === 100 &&
                                            <tr className="tr-100">
                                                <td data-label="Unit size">10&apos; x 10&apos;<br/>
                                                100 sq. ft.<br/>
                                                (3.05m x 3.05m)<br/>
                                                (9.29 sq. m.)</td>
                                                <td data-label="How to visualize">15&apos; Moving Truck<br/>
                                                (4.57m Moving Truck)<br/>
                                                Average size bedroom</td>
                                                <td data-label="What unit will hold">Furnishings of one bedroom apartment with refrigerator and washer/dryer, patio furniture and many boxes OR two office suites OR about 200 file boxes</td>
                                                <td data-label="Unit visualization"><img src={String(gif100)} alt={''} /></td>
                                            </tr>
                                            }
                                            { recommended === 150 &&
                                            <tr className="tr-150">
                                                <td data-label="Unit size">10&apos; x 15&apos;<br/>
                                                150 sq. ft.<br/>
                                                (3.05m x 4.57m)<br/>
                                                (13.94 sq. m.)</td>
                                                <td data-label="How to visualize">20&apos; Moving Truck<br/>
                                                (6.10m Moving Truck)<br/>
                                                Large bedroom</td>
                                                <td data-label="What unit will hold">Furnishings of two bedroom apartment or small house with appliances, patio furniture and many boxes OR a vehicle or small boat OR it is big enough to fit carpet rolls and building materials</td>
                                                <td data-label="Unit visualization"><img src={String(gif150)} alt={''} /></td>
                                            </tr>
                                            }
                                            {recommended === 200 &&
                                            <tr className="tr-200">
                                                <td data-label="Unit size">10&apos; x 20&apos;<br/>
                                                200 sq. ft.<br/>
                                                (3.05m x 6.10m)<br/>
                                                (18.58 sq. m.)</td>
                                                <td data-label="How to visualize">24&apos; Moving Truck<br/>
                                                (7.32m Moving Truck)<br/>
                                                Small one car garage</td>
                                                <td data-label="What unit will hold">Furnishings of two bedroom house with appliances, patio furniture and many boxes OR a vehicle or small boat OR construction equipment</td>
                                                <td data-label="Unit visualization"><img src={String(gif200)} alt={''}/></td>
                                            </tr>
                                            }
                                            {recommended === 250 &&
                                            <tr className="tr-250">
                                                <td data-label="Unit size">10&apos; x 25&apos;<br/>
                                                250 sq. ft.<br/>
                                                (3.05m x 7.62m)<br/>
                                                (23.23 sq. m.)</td>
                                                <td data-label="How to visualize">26&apos; Moving Truck<br/>
                                                (7.92m Moving Truck)<br/>
                                                Large one car garage</td>
                                                <td data-label="What unit will hold">Furnishings of three bedroom house with appliances, patio furniture and many boxes, garage extras and miscellaneous OR a vehicle or boat</td>
                                                <td data-label="Unit visualization"><img src={String(gif250)}  alt={''}/></td>
                                            </tr>
                                            }
                                            { recommended === 300 &&
                                            <tr className="tr-300">
                                                <td data-label="Unit size">10&apos; x 30&apos;<br/>
                                                300 sq. ft.<br/>
                                                (3.05m x 9.14m)<br/>
                                                (27.87 sq. m.)</td>
                                                <td data-label="How to visualize">Extra long one car garage</td>
                                                <td data-label="What unit will hold">Furnishings of four or five bedroom house with appliances, patio furniture and many boxes, garage extras and miscellaneous OR a vehicle or boat</td>
                                                <td data-label="Unit visualization"><img src={String(gif300)} alt={''} /></td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>

                                    <p>To book a unit now, please visit the <a href="/available-units" target="_blank">Units Available</a> page.</p>
                                </div>
                            }


                            {showRecommendedErrorExcess &&
                                <div className="recommendation-error recommendation-error-excess">
                                    <h3>Please give us a call</h3>

                                    <p>Your specific estimation may require assistance from one of our professionals. Please give us a call at (414) 659-3045, or head over to our <a href="/contact">Contact Us</a> page for other ways to reach us.</p>
                                </div>
                            }

                            {showRecommendedErrorZero &&
                                <div className="recommendation-error recommendation-error-zero">
                                    <h3>Please add items</h3>

                                    <p>It appears that there aren&apos;t enough items in the fields above.  Please enter how many you have and this space will update accordingly.</p>
                                </div>
                            }

                        </article>
                    </div>
                </div>
            </section>
        </div>

    );
}
