import apiService from './ApiService';

export class StorageUnitApiService {

    /**
     * Get a user's storage units
     * @param userId {number}
     * @returns {Promise<ServiceResponse<Array<StorageUnitDTO>>>}
     */
    async getUsersStorageUnits(userId) {
        const storageUnits = await apiService.getData('/api/StorageUnit/user/' + userId, null);

        return storageUnits.data;
    }

    /**
     * Gets all available storage units
     * @returns {Promise<ServiceResponse<Array<StorageUnitDTO>>>}
     */
    async getAvailableStorageUnits() {
        const storageUnits = await apiService.getData('/api/StorageUnit', null);

        return storageUnits.data;
    }

    async requestReserveStorageUnit(storageUnitId) {
        const request = await apiService.postData('/api/StorageUnit/request/' + storageUnitId, null);

        return request.data;
    }

    /**
     * Gets any already requested storage units
     * @return {Promise<ServiceResponse<Array<StorageUnitDTO>>>}
     */
    async getRequestedStorageUnits() {
        const request = await apiService.getData('/api/StorageUnit/already-requested');

        return request.data;
    }

    /**
     * Cancels a request for a storage unit
     * @param storageUnitId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async cancelRequestedStorageUnit(storageUnitId) {
        const request = await apiService.postData(`/api/StorageUnit/already-requested/${storageUnitId}`, null);

        return request.data;
    }
}
