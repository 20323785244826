import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export default function Index() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Executive Storage Suites';
    }, []);
    return (
        <div id="page-wrapper">
            <section id="banner">
                <header>
                    <h2><em>Book your unit today</em></h2>
                    <input className={'static-button'} type={'button'} style={{verticalAlign: 'middle', marginLeft: '1em', background: '#007A03', color: '#fff', borderRadius: '5px', appearance: 'none', transitionProperty: 'background-color, color, box-shadow', transitionDuration: '0.2s 0.2s 0.2s', transitionTimingFunction: 'ease-in-out', transitionDelay: '0s', border: 0, cursor: 'pointer', display: 'inline-block', padding: '0 1.5em', lineHeight: '2.75em', minWidth: '9em', textAlign: 'center', textDecoration: 'none', fontWeight: '600', letterSpacing: '-0.025em'}} onClick={() => navigate('/available-units')} value={'Book Now'} />
                </header>
            </section>

            <section className="wrapper style1">
                <div className="container">
                    <div className="row gtr-200">
                        <section className="col-4 col-12-narrower">
                            <div className="box highlight">
                                <h3><a href="/available-units">Reserve Online</a></h3>

                                <p>Book your unit with us online.</p>
                            </div>
                        </section>

                        <section className="col-4 col-12-narrower">
                            <div className="box highlight">
                                <h3><a href={'/user-storage-units'}>Pay Bill</a></h3>

                                <p>Pay your bill with a credit card online.</p>
                            </div>
                        </section>

                        <section className="col-4 col-12-narrower">
                            <div className="box highlight">
                                <h3><a href="/contact">Contact Us</a></h3>

                                <p>We&apos;re here to help, so contact us today!</p>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            <section className="wrapper style1">
                <div className="container">
                    <div id="content">
                        <article>
                            <header>
                                <h2>East Troy, WI Self-Storage</h2>
                            </header>

                            <p>Welcome to Executive Storage Suites, the number one location for all your East Troy, WI storage needs! Opened in 2016, our brand new East Troy storage facility at 2481 Executive Drive has a wide range of climate controlled units to choose from. Whether you are in need of personal storage, business storage, long or short term, we have the right solutions to accommodate all types of belongings. We are conveniently located just blocks east of highway 120 on Honeycreek Rd and Executive Drive.</p>

                            <h3>Storage Rentals</h3>

                            <p>If you are coming from the surrounding areas in Walworth County such as Mukwonago, Lake Geneva, Burlington or Elkhorn, we are easy to find and easy to get to. Our property is located in the East Troy Business Park, just blocks from the Aurora Health Center. With month to month leasing plans and affordable storage unit prices, our facility is number one for storage in the 53120 zip code.</p>

                            <h3>Easy Access &amp; Enhanced Security</h3>

                            <p>All units are conveniently located on the ground level, with easy drive-up access for unloading and moving in. Climate control offers dependable protection of your belongings against extreme temperatures and humidity. When you rent East Troy storage with Executive Storage Suites, you can count on security and safekeeping for all your storage items. Our property features recorded video surveillance, via 14 strategically positioned cameras and motion-activated lights.</p>

                            <h3>24/7 Self-Storage</h3>

                            <p>One feature that our tenants enjoy the most is 24/7 access to all units. Instead of finding time in your busy schedule to meet office hours, we allow you the freedom to store on your own time, day or night. In addition, our 24/7 kiosk lets you arrange rentals and move-ins whenever you want. Self storage can be a hassle, but it doesn’t have to be when you store with Executive Storage Suites. We look forward to storing with you!</p>
                        </article>
                    </div>
                </div>
            </section>

            <section className="wrapper style3" id="cta">
                <div className="container">
                    <header>
                        <h2>Are you ready?</h2>
                        <input type={'button'} style={{verticalAlign: 'middle', marginLeft: '1em', background: '#fff', color: '#007A03', borderRadius: '5px', appearance: 'none', transitionProperty: 'background-color, color, box-shadow', transitionDuration: '0.2s 0.2s 0.2s', transitionTimingFunction: 'ease-in-out', transitionDelay: '0s', border: 0, cursor: 'pointer', display: 'inline-block', padding: '0 1.5em', lineHeight: '2.75em', minWidth: '9em', textAlign: 'center', textDecoration: 'none', fontWeight: '600', letterSpacing: '-0.025em'}} className={'static-button'} onClick={() => navigate('/available-units')} value={'Book Now'} /></header>
                </div>
            </section>
        </div>
    );
}
