import apiService from './ApiService';

export default class PaymentTokenApiService {

    /**
     * Get all data for the payment token
     * @param paymentToken{string}
     * @return {Promise<ServiceResponse<PaymentTokenDetailsDTO>>}
     */
    async getPaymentTokenDetails(paymentToken) {
        const details = await apiService.getData(`/api/PaymentToken/${paymentToken}`);

        return details.data;
    }

    /**
     * Submit card payment info
     * @param cardData {CardPaymentDto}
     * @return {Promise<ServiceResponse>}
     */
    async submitPaymentInfo(paymentToken, cardData) {
        const response = await apiService.postData(`/api/PaymentToken/${paymentToken}`, cardData);

        return response.data;
    }

    /**
     * Sets an already registered payment method for a new storage unit
     * @param paymentToken {string}
     * @param paymentProfileId {string}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async setPaymentProfileForToken(paymentToken, paymentProfileId) {
        const response = await apiService.postData(`/api/PaymentToken/${paymentToken}/${paymentProfileId}`, null);

        return response.data;
    }
}
