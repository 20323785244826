import React, { Component } from 'react';
import authService from '../../Auth/AuthService';
import { Box, CircularProgress } from '@mui/material';

export class Logout extends Component {
    componentDidMount () {
        document.title = 'Executive Storage Suites - Logout';
        authService.logout().then(result => {
            if (result) {
                window.location.href = '/';
            }
        });
    }

    render () {
        return (<div>
            <div className="custom-logo">
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-4 pb-3 pt-3 offset-md-4 text-center">
                        <div className="logo">
                            <div className="title"><em></em></div>
                        </div>
                    </div>
                </div>
                <center>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <div>
                                <div className="logout-container">
                                    <Box sx={{display: 'flex'}}>
                                        <CircularProgress/>
                                    </Box>
                                </div>
                                <div className='logout-container'>
                                    Logging out...
                                </div>
                            </div>
                        </div>
                    </div>
                </center>
            </div>
        </div>);
    }
}
