import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/material';
import { Label, Button } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import React from 'react';
import StorageUnitDTO from '../../../DTOs/StorageUnit/StorageUnitDTO';


export default function ConfirmReserveRequestModal(props) {
    const { open, storageUnit, close, handleConfirm, loading } = props;

    return (
        <Dialog
            closeAfterTransition={true}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth="sm"
            fullWidth
            open={open}
        >
            <DialogTitle>Reserve {storageUnit?.name}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Label>Would you like to request to reserve Storage Unit {storageUnit?.name}?</Label>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className={'mobile-modal-button'} autoFocus onClick={close} disabled={loading}>
                Cancel
                </Button>
                <Button className={'mobile-modal-button'} color={'primary'} onClick={handleConfirm} disabled={loading}>{loading ? 'Requesting...' : 'Confirm'}</Button>
            </DialogActions>
        </Dialog>
    );

}

ConfirmReserveRequestModal.propTypes = {
    storageUnit: PropTypes.objectOf(StorageUnitDTO).isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
