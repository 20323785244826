import React from 'react';
import PropTypes from 'prop-types';
import ApprovalPendingPaymentDTO from '../../DTOs/Subscription/ApprovalPendingPaymentDTO';
import {Box} from '@mui/material';
import {Button, Label} from 'reactstrap';
import {USDollar} from '../../utilities/Constants';

export default function ApprovalsPendingPaymentCardMobile(props) {
    const { key, approvalPendingPayment, showRevoke} = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`Storage Unit: ${approvalPendingPayment.storageUnit.name}`}</Label>
            <Label>{`Requesting User: ${approvalPendingPayment.appUser.firstName} ${approvalPendingPayment.appUser.lastName} (${approvalPendingPayment.appUser.username})`}</Label>
            <Label>{`Price: ${USDollar.format(approvalPendingPayment.storageUnit.price)}`}</Label>
            <Label>{`Move-in Date: ${approvalPendingPayment.moveInDate.toString().substring(0, approvalPendingPayment.moveInDate.toString().length - 9)}`}</Label>
            <Label>{`Token: ${approvalPendingPayment.token}`}</Label>
            <Button className={'mobile-button'} color={'danger'} onClick={showRevoke}>Revoke Approval</Button>
        </Box>
    );
}

ApprovalsPendingPaymentCardMobile.propTypes = {
    key: PropTypes.number,
    approvalPendingPayment: PropTypes.objectOf(ApprovalPendingPaymentDTO).isRequired,
    showRevoke: PropTypes.func.isRequired,
};
