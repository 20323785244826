import React from 'react';
import PropTypes from 'prop-types';
import AppUserGenericDTO from '../../DTOs/User/AppUserGenericDTO';
import {Box} from '@mui/material';
import {Label} from 'reactstrap';

export default function UserCardMobile(props) {
    const {key, user} = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`E-mail: ${user.username}`}</Label>
            <Label>{`Full Name: ${user.firstName} ${user.lastName}`}</Label>
            <Label>{`Phone #: ${user.phone}`}</Label>
            <Label>{`Driver's License #: ${user.driversLicenseNumber}`}</Label>
            <Label>{`License State: ${user.licenseState}`}</Label>
            <Label>{`Door Code: ${user.doorCode}`}</Label>
            <Label>{`Address 1: ${user.address1 ?? ''}`}</Label>
            <Label>{`Address 2: ${user.address2 ?? ''}`}</Label>
            <Label>{`City: ${user.city ?? ''}`}</Label>
            <Label>{`State: ${user.state ?? ''}`}</Label>
            <Label>{`Zip: ${user.zip ?? ''}`}</Label>
        </Box>
    );
}

UserCardMobile.propTypes = {
    key: PropTypes.number,
    user: PropTypes.objectOf(AppUserGenericDTO)
};
