export default class ContactFormDTO {
    /**
     * @type{string}
     */
    name;

    /**
     * @type{string}
     */
    email;

    /**
     * @type{string}
     */
    message;

    constructor() {
        this.name = '';
        this.email = '';
        this.message = '';
    }
}
