/**
 * @class
 */
export default class StorageUnitMaintenanceDTO {

    /**
     * @type {?number}
     */
    id;

    /**
     * @type {string}
     */
    name;

    /**
     * @type {number=}
     */
    currentUserId;

    /**
     * @type {AppUserDTO}
     */
    currentUser;

    /**
     * @type {number}
     */
    sectionId;

    /**
     * @type {SectionDTO}
     */
    section;

    /**
     * @type {number}
     */
    price;

    /**
     * @type {boolean}
     */
    available;

    /**
     * @type {number}
     */
    facilityId;

    /**
     * @type {FacilityDTO}
     */
    facility;

    /**
     * @type {number}
     */
    storageUnitTypeId;

    /**
     * @type{StorageUnitTypeDTO}
     */
    storageUnitType;

    /**
     * @type {boolean}
     */
    climateControlled;

    /**
     * @type {string}
     */
    storageSize;

    /**
     * @type {Date=}
     */
    nextReadyDate;

    /**
     * @type {boolean}
     */
    hold;

    constructor() {
        this.id = 0;
        this.name = '';
        this.sectionId = 0;
        this.price = 0;
        this.available = true;
        this.facilityId = 0;
        this.storageUnitTypeId = 1;
        this.climateControlled = true;
        this.storageSize = '';
        this.hold = false;
    }

}
