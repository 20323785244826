import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, CircularProgress, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import PaymentTokenApiService from '../../api/PaymentTokenApiService';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import images from 'react-payment-inputs/images';
import {PaymentInputsWrapper, usePaymentInputs} from 'react-payment-inputs';
import CardPaymentDto from '../../DTOs/Subscription/CardPaymentDto';
import {PaymentFrequency} from '../../DTOs/Subscription/CardPaymentDto';
import Backdrop from '@mui/material/Backdrop';
import * as Constants from '../../utilities/Constants';
import SubscriptionApiService from '../../api/SubscriptionApiService';
import authenticationManager from '../../Auth/AuthenticationManager';

export default function NewSubscriptionPaymentPage(props) {
    const { setSnackbarProperties, isMobile } = props;

    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
    } = usePaymentInputs();

    const[cardNumber, setCardNumber] = useState('');
    const[expirationDate, setExpirationDate] = useState('');
    const[cvv, setCvv] = useState('');
    const[paymentTokenDetails, setPaymentTokenDetails] = useState();
    const[loading, setLoading] = useState(true);
    const[currentPaymentOptions, setCurrentPaymentOptions] = useState([]);
    const[currentSelectedPaymentOption, setCurrentSelectedPaymentOption] = useState();
    const[error, setError] = useState('');

    const newPaymentMethodRadioValue = '0';
    const isNewPaymentMethodSelected = useMemo(() => currentSelectedPaymentOption === parseInt(newPaymentMethodRadioValue), [currentSelectedPaymentOption]);
    const doPaymentMethodsAlreadyExist = useMemo(() => currentPaymentOptions.length > 0, [currentPaymentOptions]);

    const params = useParams();
    const token = params.token;

    const navigate = useNavigate();

    const paymentTokenApiService = new PaymentTokenApiService();
    const subscriptionApiService = new SubscriptionApiService();

    async function getPaymentDetails() {
        setLoading(true);
        const alreadyExistingPaymentMethods = await subscriptionApiService.getUserPaymentMethods(authenticationManager.getUserId());

        if(alreadyExistingPaymentMethods.success) {
            setCurrentPaymentOptions(alreadyExistingPaymentMethods.data);
        }

        const response = await paymentTokenApiService.getPaymentTokenDetails(token);

        if(response.success) {
            setPaymentTokenDetails(response.data);
        } else {
            setSnackbarProperties(`Error: ${response.message}`, 'error');
        }

        setLoading(false);
    }

    function validate() {
        setError('');
        if(isNewPaymentMethodSelected || !doPaymentMethodsAlreadyExist) {
            const cardNumberData = cardNumber.replaceAll(' ', '');
            const expirationData = expirationDate.replace('/', '').replaceAll(' ', '');

            if(cardNumberData.length !== 15 || cardNumberData.length !== 16) {
                setError('Card number does not appear to be valid');
            }

            if(expirationData.length !== 4) {
                setError('Expiration date does not appear to be valid');
            }

            if((cvv.length !== 3 && cvv.length !== 4) || Number.isNaN(cvv)){
                setError('Card Code does not appear to be valid');
            }

            return (cardNumberData.length === 16 || cardNumberData.length === 15) && expirationData.length === 4 && (cvv.length === 3 || cvv.length === 4) && !Number.isNaN(cvv);
        }

        return currentSelectedPaymentOption > 0;
    }

    async function submitPayment() {
        const isValid = validate();

        if(isValid) {
            const cardPaymentDetails = new CardPaymentDto();

            cardPaymentDetails.cardNumber = cardNumber;
            cardPaymentDetails.cardExpiration = expirationDate;
            cardPaymentDetails.cvv = cvv;
            cardPaymentDetails.paymentFrequency = PaymentFrequency.Monthly;

            let response;
            if((doPaymentMethodsAlreadyExist && isNewPaymentMethodSelected) || !doPaymentMethodsAlreadyExist) {
                response = await paymentTokenApiService.submitPaymentInfo(token, cardPaymentDetails);
            } else {
                response = await paymentTokenApiService.setPaymentProfileForToken(token, currentSelectedPaymentOption);
            }

            if(response.success) {
                setSnackbarProperties('Successfully submitted payment!');
                navigate('/');
            } else if(response.message) {
                setSnackbarProperties(`Error: ${response.message}`, 'error');
            } else {
                setSnackbarProperties(`Error: ${response}`, 'error');
            }
        } else {
            setSnackbarProperties(`Errors on form: ${error}`, 'error');
        }

    }

    function handleCardNumberChange(event) {
        setCardNumber(event.target.value);
    }

    function handleExpirationDateChange(event) {
        setExpirationDate(event.target.value);
    }

    function handleCVCChange(event) {
        setCvv(event.target.value);
    }

    useEffect(() => {
        document.title = 'Executive Storage Suites - New Subscription';
        if(authenticationManager.isAuthorized) {
            getPaymentDetails();
        } else {
            setSnackbarProperties('You must log in to continue', 'warning');
            localStorage.setItem('callback', 'paymentToken');
            navigate('/');
        }

    }, []);

    return(
        <Box>
            <Backdrop open={loading}><CircularProgress color={'inherit'}/></Backdrop>
            <center>
                <h3>Payment Card Details</h3>
                <h3>This payment will take out a transaction for the first month&apos;s rent (or prorated amount for early move-in) and the security deposit amount for the following amounts:</h3>

                <Grid container alignItems={'center'} justifyContent={'center'}>
                    <Box sx={{border: 1, borderColor: 'grey.500', padding: '10px', borderRadius: '10px', marginTop: '20px', marginBottom: '20px', width: '100%'}}>
                        <Grid item md={12} xs={12}>
                            <h5>Security Deposit (One Month&apos;s Rent): {Constants.USDollar.format(paymentTokenDetails?.securityDepositAmount)}</h5>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <h5>Prorated payment for move-in day of {paymentTokenDetails?.moveInDate.toString().substring(0, paymentTokenDetails?.moveInDate.toString().length - 9)}: {Constants.USDollar.format(paymentTokenDetails?.proRatedAmount)}</h5>
                        </Grid>
                        <Grid item md={12} xs={12} sx={{marginBottom: '20px'}}>
                            <h5>Total due today: {Constants.USDollar.format(paymentTokenDetails?.totalDueTodayMonthly)}</h5>
                        </Grid>
                    </Box>
                    <Grid item md={12} xs={12} sx={{marginBottom: '20px'}}>
                        {doPaymentMethodsAlreadyExist &&
                                <RadioGroup
                                    value={currentSelectedPaymentOption}
                                    onChange={(event) => setCurrentSelectedPaymentOption(parseInt(event.target.value))}
                                    style={{justifyItems: 'center'}}
                                >
                                    <center>
                                        <Grid container>
                                            {currentPaymentOptions.map((paymentOption, index) =>
                                                <Grid item key={index} justifyContent={'center'} md={12}>
                                                    <FormControlLabel className={paymentOption.isExpired ? 'expired-card-option' : ''} key={index} value={parseInt(paymentOption.paymentProfileId)}
                                                        control={<Radio
                                                            checked={currentSelectedPaymentOption === parseInt(paymentOption.paymentProfileId)}
                                                            disabled={paymentOption.isExpired}
                                                        />}
                                                        label={`${paymentOption.cardType} - ${paymentOption.cardNumber} ${paymentOption.isExpired ? '- EXPIRED' : '' }`}/></Grid>

                                            )}
                                        </Grid>

                                        <FormControlLabel control={<Radio/>} label={'New payment option'}
                                            value={newPaymentMethodRadioValue}/>
                                        {isNewPaymentMethodSelected && !isMobile &&
                                        <Grid item>
                                            <PaymentInputsWrapper
                                                style={{maxWidth: '400px'}}
                                                {...wrapperProps}
                                            >
                                                {cardNumber.length > 0 && <svg {...getCardImageProps({images})} /> }
                                                <input {...getCardNumberProps({onChange: handleCardNumberChange})} />
                                                <input {...getExpiryDateProps({onChange: handleExpirationDateChange})} />
                                                <input {...getCVCProps({onChange: handleCVCChange})} />
                                            </PaymentInputsWrapper>
                                        </Grid>
                                        }
                                        {isNewPaymentMethodSelected && isMobile &&
                                                <Grid item>
                                                    <div>
                                                        {cardNumber.length > 0 && <svg className={'mobile-card-image'} {...getCardImageProps({images})} />}
                                                        <input className={'mobile-card-number-input'} {...getCardNumberProps({onChange: handleCardNumberChange})} value={cardNumber} />
                                                        <input className={'mobile-exp-date-input'} {...getExpiryDateProps({onChange: handleExpirationDateChange})} value={expirationDate} />
                                                        <input className={'mobile-cvc-input'} {...getCVCProps({onChange: handleCVCChange})} value={cvv}/>
                                                    </div>
                                                </Grid>
                                        }
                                    </center>
                                </RadioGroup>
                        }
                    </Grid>
                    { !doPaymentMethodsAlreadyExist && !isMobile &&
                    <Grid item md={3} xs={3} sx={{marginBottom: '20px', maxWidth: '34%'}} alignItems={'center'} justifyItems={'center'}>
                        <PaymentInputsWrapper
                            {...wrapperProps}
                        >
                            {cardNumber.length > 0 && <svg {...getCardImageProps({images})} /> }
                            <input {...getCardNumberProps({onChange: handleCardNumberChange})} />
                            <input {...getExpiryDateProps({onChange: handleExpirationDateChange})} />
                            <input {...getCVCProps({onChange: handleCVCChange})} />
                        </PaymentInputsWrapper>
                    </Grid>
                    }
                    { !doPaymentMethodsAlreadyExist && isMobile &&
                        <Grid item md={3} xs={3} sx={{marginBottom: '20px'}} alignItems={'center'} justifyItems={'center'}>
                            <div>
                                {cardNumber.length > 0 && <svg className={'mobile-card-image'} {...getCardImageProps({images})} />}
                                <input className={'mobile-card-number-input'} {...getCardNumberProps({onChange: handleCardNumberChange})} value={cardNumber} />
                                <input className={'mobile-exp-date-input'} {...getExpiryDateProps({onChange: handleExpirationDateChange})} value={expirationDate} />
                                <input className={'mobile-cvc-input'} {...getCVCProps({onChange: handleCVCChange})} value={cvv}/>
                            </div>
                        </Grid>
                    }
                    <Grid item md={12} xs={12}>
                        <Button disabled={loading} variant={'contained'} onClick={submitPayment}>Submit</Button>
                    </Grid>
                </Grid>
            </center>
        </Box>
    );
}

NewSubscriptionPaymentPage.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
