import React, {useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {StorageUnitApiService} from '../../api/StorageUnitApiService';
import PropTypes from 'prop-types';
import ConfirmReserveRequestModal from '../Modal/StandardFunctions/ConfirmReserveRequestModal';
import StorageUnitCardMobile from '../Mobile/StorageUnitCardMobile';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as Constants from '../../utilities/Constants';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import {Label} from 'reactstrap';
import InformationDialog from '../Modal/StandardFunctions/InformationDialog';

export default function AvailableStorageUnits(props) {
    const { setSnackbarProperties, isMobile } = props;

    const [storageUnits, setStorageUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showReserveConfirmDialog, setShowReserveConfirmDialog] = useState(false);
    const [selectedStorageUnit, setSelectedStorageUnit] = useState();
    const [alreadyRequestedUnits, setAlreadyRequestedUnits] = useState([]);
    const [showConfirmCancelRequest, setShowConfirmCancelRequest] = useState(false);
    const [reloadPage, setReloadPage] = useState(0);
    const [showInfoDialog, setShowInfoDialog] = useState(false);

    const storageUnitApiService = new StorageUnitApiService();

    const navigate = useNavigate();

    const columns= [
        {
            field: 'name',
            headerName: 'Storage Unit',
            flex: 1,
        },
        {
            field: 'storageSize',
            headerName: 'Storage Size',
            flex: 1,
        },
        {
            field: 'storageUnitType',
            headerName: 'Storage Type',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnitType.name;
            }
        },
        {
            field: 'climateControlled',
            headerName: 'Climate Controlled',
            flex: 1,
            renderCell: (params) => {
                return params.row.climateControlled ? 'Yes' : 'No';
            }
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => {
                return Constants.USDollar.format(params.row.price);
            }
        },
        {
            field: 'nextReadyDate',
            headerName: 'Available Date',
            flex: 1,
            renderCell: (params) => {
                const readyDate = params.row.nextReadyDate;
                return readyDate ? readyDate.toString().substring(0, readyDate.toString().length - 9) : 'Now';
            }

        },
        {
            flex: 0.75,
            renderCell: (params) => {
                if(alreadyRequestedUnits.findIndex((request) => request.id === params.row.id) > -1) {
                    return (
                        <Button sx={{width: '100%'}} variant={'contained'} color={'warning'} onClick={() => handleCancelRequestButtonClick(params.row)}>Cancel Request</Button>
                    );
                }
                return (
                    <Button sx={{width: '100%'}} variant={'contained'} onClick={() => {
                        confirmReserveRequest(params.row);
                    }}>Reserve</Button>
                );
            }
        }
    ];

    function confirmReserveRequest(storageUnit) {
        setSelectedStorageUnit(storageUnit);
        setShowReserveConfirmDialog(true);
    }

    function handleCancelRequestButtonClick(storageUnit) {
        setSelectedStorageUnit(storageUnit);
        setShowConfirmCancelRequest(true);
    }

    async function handleConfirm() {
        setLoading(true);
        const request =  await storageUnitApiService.requestReserveStorageUnit(selectedStorageUnit.id);

        if(request.success) {
            if(request.data) {
                setSnackbarProperties('Successfully requested to reserve Storage Unit');
                setReloadPage(reloadPage === 100 ? 0 : reloadPage + 1);
                setShowInfoDialog(true);
            } else {
                setSnackbarProperties('You have already requested this storage unit', 'warning');
            }
            setShowReserveConfirmDialog(false);

        } else {
            setSnackbarProperties('Unable to reserve unit: ' + request.message, 'error');
        }
        setLoading(false);
    }

    async function getAvailableUnits() {
        setLoading(true);
        const storageUnitResponse = await storageUnitApiService.getAvailableStorageUnits();

        if(storageUnitResponse.success) {
            setStorageUnits(storageUnitResponse.data);
        } else {
            setSnackbarProperties('Unable to retrieve available storage units', 'error');
        }

        await getRequestedUnits();

        setLoading(false);
    }

    async function handleCancelRequest() {
        const response = await storageUnitApiService.cancelRequestedStorageUnit(selectedStorageUnit.id);

        if(response.success) {
            setSelectedStorageUnit(undefined);
            setSnackbarProperties('Successfully cancelled request', 'warning');
        } else {
            setSnackbarProperties(`Error cancelling request: ${response.message}`, 'error');
        }

        setShowConfirmCancelRequest(false);
        setReloadPage(reloadPage === 100 ? 0 : reloadPage + 1);
    }

    async function getRequestedUnits() {
        const alreadyRequestedUnitsResponse = await storageUnitApiService.getRequestedStorageUnits();

        if(alreadyRequestedUnitsResponse.success) {
            setAlreadyRequestedUnits(alreadyRequestedUnitsResponse.data);
        }
    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized) {
            navigate('/login');
        }
        document.title = 'Executive Storage Suites - Available Storage Units';
        getAvailableUnits();
    }, [reloadPage]);

    return(
        <Box>
            <h1>Available Storage Units</h1>
            { isMobile ? loading ? <Backdrop open={loading}><CircularProgress color={'inherit'}/></Backdrop> : storageUnits.length > 0 ? storageUnits?.map((unit, index) => {
                return <StorageUnitCardMobile
                    key={index}
                    storageUnit={unit}
                    isOwned={false}
                    setOpenReserveUnit={() => confirmReserveRequest(unit)}
                    isRequested={alreadyRequestedUnits.findIndex((alreadyRequestedUnit) => alreadyRequestedUnit.id === unit.id) > -1}
                    setOpenCancelRequest={() => {handleCancelRequestButtonClick(unit);}}
                />;
            }

            )
                :
                <Label>There are no available storage units</Label>
                :
                <Box sx={{height: '80vh'}}>
                    <DataGrid
                        rows={storageUnits}
                        columns={columns}
                        loading={loading}
                        getRowHeight={() => 'auto'}
                    />
                </Box>

            }

            <ConfirmReserveRequestModal
                open={showReserveConfirmDialog}
                close={() => setShowReserveConfirmDialog(false)}
                handleConfirm={handleConfirm}
                storageUnit={selectedStorageUnit}
                loading={loading}
            />

            <ConfirmDeleteModal
                open={showConfirmCancelRequest}
                title={'Cancel Reservation Request'}
                confirmText={'Are you sure you wish to cancel your reservation request?'}
                handleConfirm={handleCancelRequest}
                close={() => setShowConfirmCancelRequest(false)}
                isMobile={isMobile}
            />
            <InformationDialog
                open={showInfoDialog}
                title={'Reservation Requested'}
                text={'Your reservation has been requested successfully. You will receive an email with a link to the lease.'}
                close={() => setShowInfoDialog(false)}
                isMobile={isMobile}
            />
        </Box>
    );
}

AvailableStorageUnits.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
