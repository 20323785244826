import React, {useEffect, useState} from 'react';
import {Box, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import UserApiService from '../../api/UserApiService';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import UserDetailPanel from './DetailPanels/UserDetailPanel';
import UserCardMobile from '../Mobile/UserCardMobile';

export default function UsersAdmin(props) {
    const {setSnackbarProperties, isMobile} = props;

    const[users, setUsers] = useState([]);
    const[loading, setLoading] = useState(false);
    const[searchableUsers, setSearchableUsers] = useState([]);
    const[search, setSearch] = useState('');

    const userApiService = new UserApiService();

    const navigate = useNavigate();

    const columns= [
        {
            field: 'username',
            headerName: 'E-mail',
            flex: 1,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Phone #',
            flex: 1,
        },
        {
            field: 'driversLicenseNumber',
            headerName: 'Driver\'s License Number',
            flex: 1,
        },
        {
            field: 'licenseState',
            headerName: 'License State',
            flex: 1,
        },
        {
            field: 'doorCode',
            headerName: 'Door Code',
            flex: 1,
        }
    ];

    const getDetailPanelContent = React.useCallback(({row}) => <UserDetailPanel row={row} />, []);
    const getDetailPanelHeight = React.useCallback(() => 300, []);

    async function getUsers() {
        setLoading(true);
        const userResponse = await userApiService.getAllUsersGeneric();

        if(userResponse.success) {
            setUsers(userResponse.data);
            setSearchableUsers(userResponse.data);
        } else {
            setSnackbarProperties(`Error getting users: ${userResponse.message}`, 'error');
        }
        setLoading(false);
    }

    function handleSearchChange(value) {
        setSearch(value);
        if(value === '') {
            setSearchableUsers(users);
        } else {
            setSearchableUsers(users.filter((user) => user.username.toUpperCase().includes(value.toUpperCase()) || user.firstName.toUpperCase().includes(value.toUpperCase()) || user.lastName.toUpperCase().includes(value.toUpperCase()) || user.phone.includes(value)));
        }
    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized || (!authenticationManager.isAdmin() && !authenticationManager.isSuperAdmin())) {
            navigate('/login');
        }

        document.title = 'Executive Storage Suites - Users';

        getUsers();
    }, []);

    return (
        <Box>
            <h1>Users</h1>
            <TextField sx={{marginBottom: '10px'}} label={'Search'} value={search} onChange={(event) => handleSearchChange(event.target.value)}/>
            {isMobile ?
                <Box>

                    {searchableUsers.map((user, index) => {
                        return <UserCardMobile key={index} user={user} />;
                    })
                    }
                </Box>
                :
                <Box sx={{height: '80vh'}}>

                    <DataGridPro
                        columns={columns}
                        rows={searchableUsers}
                        loading={loading}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            Toolbar: {showQuickFilter: true, quickFilterProps: {debounceMs: 500}}
                        }}
                        getRowHeight={() => 'auto'}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                    />
                </Box>
            }
        </Box>

    );
}

UsersAdmin.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
