import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Box, Button, FormLabel} from '@mui/material';
import authenticationManager from '../../Auth/AuthenticationManager';
import UserApiService from '../../api/UserApiService';
import {CardTitle} from 'reactstrap';
import PropTypes from 'prop-types';
import EditUserModal from '../Modal/User/EditUserModal';
import {useNavigate} from 'react-router-dom';

export default function UserInfo(props) {
    const { setSnackbarProperties, isDesktop } = props;
    const [user, setUser] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);

    const userApiService = new UserApiService();

    const navigate = useNavigate();

    async function getUserInfo(userId) {
        const userInfo = await userApiService.getUserInfo(userId);

        if(userInfo.success) {
            setUser(userInfo.data);
        } else {
            setSnackbarProperties('Unable to retrieve user info', 'error');
        }
    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized) {
            navigate('/login');
        }
        document.title = 'Executive Storage Suites - Your Info';
        const userId = authenticationManager.getUserId();
        getUserInfo(userId);
    }, [showEditModal]);

    return(
        <Box sx={{border: isDesktop ? 0 : 1, borderColor: isDesktop ? '' : 'grey.500', padding: '10px', borderRadius: isDesktop ? '0px' : '10px'}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CardTitle>Welcome, {user.firstName}</CardTitle>
                </Grid>
                <Grid item xs={12}>
                    <Button size="small" variant={'contained'} onClick={() => setShowEditModal(true)}>Edit your info</Button>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>E-Mail/Username: {user.username}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>First Name: {user.firstName}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Last Name: {user.lastName}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Phone #: {user.phone}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Address 1: {user.address1}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Address 2: {user.address2}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>City: {user.city}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>State: {user.state}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Zip: {user.zip}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Driver&apos;s License Number: {user.driversLicenseNumber}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Driver&apos;s License Issued in State: {user.licenseState}</FormLabel>
                </Grid>
            </Grid>
            <EditUserModal
                onClose={() => setShowEditModal(false)}
                userInfo={user}
                open={showEditModal}
                userApiService={userApiService}
                onCancel={() => setShowEditModal(false)}
                setSnackbarProperties={setSnackbarProperties}
            />
        </Box>
    );
}

UserInfo.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isDesktop: PropTypes.bool,
};
