import React from 'react';
import Login from './Authorization/Login';
import PropTypes from 'prop-types';

export default function Home(props) {

    const { isMobile, setSnackbarProperties } = props;

    return (
        <div>
            <Login isMobile={isMobile} setSnackbarProperties={setSnackbarProperties}/>
        </div>
    );
}

Home.propTypes = {
    isMobile: PropTypes.bool,
    setSnackbarProperties: PropTypes.func,
};
