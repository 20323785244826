import apiService from './ApiService';

export default class SettingsApiService {

    /**
     * Gets a setting
     * @param {string} settingName
     * @returns {Promise<SettingDTO>}
     */
    async getSetting(settingName) {
        const setting = await apiService.getData('api/Setting/' + settingName);
        return setting.data.data;
    }

    /**
     * Gets the name for the website
     * @return {Promise<SettingDTO>}
     */
    async getSiteName() {
        const siteName = await apiService.getData('api/Setting/name/brand');
        return siteName.data.data;
    }

    /**
     * Gets the main site for the company
     * @return {Promise<SettingDTO>}
     */
    async getCompanyWebsite() {
        const siteAddress = await apiService.getData('api/Setting/name/site');
        return siteAddress.data.data;
    }
}
