import jwtDecode from 'jwt-decode';
import authService from './AuthService';
import NavigationItemApiService from '../api/NavigationItemApiService';

const tokenKey = 'authToken';
const refreshTokenKey = 'refreshToken';

class AuthenticationManager {
    isAuthorized = false;
    _token;
    _user = null;
    checkingToken = false;
    subscribers = [];

    navigationItemServiceApi = new NavigationItemApiService();
    navigationItems;

    // Allow components to subscribe with a callback function
    subscribe (callback) {
        this.subscribers.push(callback);
    }

    // When a component dismounts it should unsubscribe
    unsubscribe (callback) {
        this.subscribers = this.subscribers.filter((subscriber) => subscriber !== callback);
    }

    // Set if the user is authorized or not and notify all subscribers
    setIsAuthorized (isAuthorized) {
        this.isAuthorized = isAuthorized;
        this.notifySubscribers();
    }

    // Notify all the subscribers with the callbacks
    notifySubscribers () {
        this.subscribers.forEach((subscriber) => subscriber());
    }

    tokenUpdated (newToken) {
        this._token = newToken;
    }

    constructor () {
        const token = window.localStorage.getItem(tokenKey);

        this._processToken(token);
        this.navigationItems = [{}];

        window.addEventListener('storage', (event) => {
            if (event.key === tokenKey) {
                const newToken = event.newValue;
                this._processToken(newToken);
                this.tokenUpdated(newToken);
            }
        }, false);
    }

    async getNavigationItems() {
        const navigationItemsResponse = await this.navigationItemServiceApi.GetNavigationItems();

        console.log(navigationItemsResponse);

        if(navigationItemsResponse.success) {
            this.navigationItems = navigationItemsResponse.data;
        }

    }

    async checkTokenExpiration () {
        const token = window.localStorage.getItem(tokenKey);

        if (token) {
            const header = jwtDecode(token);

            if (header && header.exp < (Date.now()) / 1000) {
                if (!this.checkingToken) {
                    this.checkingToken = true;
                    const result = await authService.refresh(this.getRefreshToken(), this.getUsername());

                    if (result == null) {
                        // Refresh failed, go back to login
                        this.updateToken(null, null, null);
                        window.location.href = '/';
                        this.checkingToken = false;
                        this.setIsAuthorized(false);
                        return Promise.resolve();
                    }

                    this.setIsAuthorized(true);
                    this.checkingToken = false;
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 250));
                    await this.checkTokenExpiration();
                }
            }
        }

        return Promise.resolve();
    }

    _processToken (token) {
        if (!token) {
            this.setIsAuthorized(false);
            return;
        }

        this._token = token;
        this._user = null;

        try {
            this._user = jwtDecode(token);
            this.setIsAuthorized(true);
        } catch (error) {
            this.setIsAuthorized(false);
            console.log(error);
        }
    }

    getUsername () {
        const token = window.localStorage.getItem(tokenKey);
        return jwtDecode(token).unique_name;
    }

    updateToken (token, refreshToken) {
        this._token = token;
        this._processToken(token);

        if (token) {
            this.setIsAuthorized(true);
            window.localStorage.setItem(tokenKey, token);
        } else {
            window.localStorage.removeItem(tokenKey);
        }

        if (refreshToken) {
            window.localStorage.setItem(refreshTokenKey, refreshToken);
        } else {
            window.localStorage.removeItem(refreshTokenKey);
        }
    }

    isUserAuthorized () {
        return this.isAuthorized;
    }

    getAccessToken () {
        const result = window.localStorage.getItem(tokenKey);

        if (!result) {
            return '';
        }

        return result;
    }

    getRefreshToken () {
        const result = window.localStorage.getItem(refreshTokenKey);

        if (!result) {
            return '';
        }

        return result;
    }

    logout () {
        this.updateToken(null, null);
    }

    isAdmin () {
        const token = window.localStorage.getItem(tokenKey);
        const tokenDecode = jwtDecode(token);
        return tokenDecode.Admin === 'True';
    }

    isSuperAdmin () {
        const token = window.localStorage.getItem(tokenKey);
        const tokenDecode = jwtDecode(token);
        return tokenDecode.SuperAdmin === 'True';
    }

    getUserId () {
        const token = window.localStorage.getItem(tokenKey);
        const tokenDecode = jwtDecode(token);
        return tokenDecode.nameid;
    }
}

const authenticationManager = new AuthenticationManager();
export default authenticationManager;
