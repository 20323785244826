import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;

export default function StandardUserEditButton(props) {
    const { params, setShowDeleteDialog, setShowEditDialog, setCurrentSelectedObject, editButtonText, deleteButtonText, showEditButton = true, showDeleteButton = true, disableEditButton = false, disableDeleteButton = false } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onEditClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        setCurrentSelectedObject(params.row);
        setShowEditDialog(true);
        handleClose();
    };

    const onDeleteClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        setCurrentSelectedObject(params.row);
        setShowDeleteDialog(true);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return <div>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >
            {showEditButton &&
                <MenuItem disabled={disableEditButton} key={1} onClick={(e) => {
                    onEditClick(e);
                }}>
                    {editButtonText}
                </MenuItem>
            }
            {showDeleteButton &&
                <MenuItem disabled={disableDeleteButton} key={2} onClick={(e) => {
                    onDeleteClick(e);
                }}>
                    {deleteButtonText}
                </MenuItem>
            }
        </Menu>
    </div>;
}

StandardUserEditButton.propTypes = {
    setShowDeleteDialog: PropTypes.func.isRequired,
    setShowEditDialog: PropTypes.func.isRequired,
    setCurrentSelectedObject: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    editButtonText: PropTypes.string,
    deleteButtonText: PropTypes.string,
    showEditButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    disableEditButton: PropTypes.bool,
    disableDeleteButton: PropTypes.bool,
};
