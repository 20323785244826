import React, {useEffect} from 'react';

export default function Features() {
    const featuresBanner = require('../../resources/css/images/features-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - Features';
    }, []);
    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">
                        <article>
                            <header>
                                <h2>East Troy, WI Storage Features &amp; Services</h2>
                            </header>
                            <span className="image featured"><img alt="" src={String(featuresBanner)} /></span>

                            <p>With a wide range of climate controlled storage units to choose from, 24/7 access and enhanced security to keep belongings safe, Executive Storage Suites is the smart choice for quality storage. Opened in 2016, our facility has a long list of exceptional features and services that make our East Troy storage number one for business and household storage in Walworth County.</p>

                            <h3>Climate Controlled Storage</h3>

                            <p>All units are climate controlled, providing cool spaces in the summer and heat during the winter for protection against wide ranging temperatures and humidity. Storage spaces are ground level, offering easy drive-up access for unloading and moving in. Whether you are looking for a place to store your belongings for a few months or a few years, we provide monthly leasing plans that are ideal for long or short term storage.</p>

                            <h3>Secure Storage</h3>

                            <p>Security for your belongings is top priority here at Executive Storage Suites, and you can count on our recorded video surveillance for secure storage of your items. Our facility is equipped with a motion detector for extra protection and safety. Check out all that we have to offer!</p>

                            <h3>Convenience</h3>

                            <p></p>

                            <ul>
                                <li>Clean, Affordable Units</li>
                                <li>Climate Controlled Units</li>
                                <li>Business File Storage</li>
                                <li>Well-Lit Aisles &amp; Buildings</li>
                                <li>Manager Available Afterhours by Phone</li>
                                <li>Dolly &amp; Flatbed Carts Available for Free Onsite Use</li>
                            </ul>

                            <p></p>

                            <h3>Access</h3>

                            <p></p>

                            <ul>
                                <li>24/7 Access to Units</li>
                                <li>All Units on Ground Level - No Stairs or Elevators</li>
                                <li>Ground Units Have Drive-Up Access</li>
                                <li>Easy Access from I-43 &amp; State Rd 120</li>
                                <li>Roll-Up Doors</li>
                            </ul>

                            <p></p>

                            <h3>Security</h3>

                            <p></p>

                            <ul>
                                <li>Recorded Video Surveillance</li>
                                <li>Motion Activated Lights in Hallways</li>
                                <li>Electronic Building Access with Code</li>
                                <li>Tenants Provide Own Padlock</li>
                            </ul>

                            <p></p>

                            <h3>Payment</h3>

                            <p></p>

                            <ul>
                                <li>Kiosk for 24/7 Rental/Move-In</li>
                                <li>Month to Month Rentals</li>
                                <li>Auto-Pay Available</li>
                                <li>Online Bill Pay</li>
                            </ul>

                            <p></p>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    );
}
