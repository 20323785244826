import React, {useEffect, useState} from 'react';
import authService from '../../Auth/AuthService';
import { FormGroup } from 'reactstrap';
import {Box, Button, FormLabel, TextField} from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import PasswordResetModal from '../Modal/StandardFunctions/PasswordResetModal';

const Login = (props) => {
    const { isMobile, setSnackbarProperties } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

    const navigate = useNavigate();

    async function handleSubmit () {
        setLoggingIn(true);
        if (validate()) {
            const result = await authService.login(username, password);
            if (result.data.success) {
                redirectToUserInformationPage();
            } else {
                setPassword('');
                setLoginError(result.data.message);
            }
        }

        setLoggingIn(false);
    }

    function redirectToUserInformationPage () {
        if(localStorage.getItem('callback')) {
            navigate(-1);
            localStorage.removeItem('callback');
        } else {
            navigate('/user-info');
        }

    }

    async function handleKeyPress(event) {
        if(event.key === 'Enter') {
            event.preventDefault();
            await handleSubmit();
        }
    }

    function validate () {
        if (username === '') {
            setUsernameError('Username must be entered');
        } else {
            setUsernameError('');
        }
        if (password === '') {
            setPasswordError('Password must be entered');
        } else {
            setPasswordError('');
        }

        if (usernameError || passwordError) {
            setLoginError('');
            return false;
        }

        return true;
    }

    function handleResetPasswordButton() {
        setShowPasswordResetModal(true);
    }

    function closeResetModal() {
        setShowPasswordResetModal(false);
    }

    async function handleResetPasswordRequest(email) {
        setResettingPassword(true);
        const response = await authService.requestResetPassword(email);

        if(response.success) {
            setSnackbarProperties(response.message);
        } else if(response.message){
            setSnackbarProperties(response.message, 'error');
        } else {
            setSnackbarProperties(response, 'error');
        }
        setResettingPassword(false);
        closeResetModal();
    }

    useEffect(() => {
        if(authenticationManager.isAuthorized) {
            navigate('/user-info');
        }
        document.title = 'Executive Storage Suites - Login';
    }, [isMobile]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                <FormGroup className={isMobile ? 'form-mobile' : 'form-desktop'}>
                    <Grid item>
                        <h1>Login</h1>
                        <h7>*You must create an account and log in, in order to rent a unit or see your current storage units</h7>
                        <span className="text-danger">{loginError}</span>
                    </Grid>
                    <Grid item sx={{marginTop: '20px'}}>
                        <FormLabel>Username</FormLabel>
                        <TextField
                            sx={{width: '100%'}}
                            autoComplete="username"
                            value={username}
                            onChange={(newValue) => {
                                setUsername(newValue.target.value);
                            }}
                            aria-required="true"/>
                        <span className="text-danger">{usernameError}</span>
                    </Grid>
                    <Grid item sx={{marginTop: '30px'}}>
                        <FormLabel>Password</FormLabel>
                        <TextField
                            sx={{ width: '100%' }}
                            value={password}
                            onChange={(newValue) => {
                                setPassword(newValue.target.value);
                            }}
                            aria-required="true"
                            type={'password'}
                            onKeyDown={handleKeyPress}
                        />
                        <span className="text-danger">{passwordError}</span>
                    </Grid>
                    <span className="text-danger" id="msg">&nbsp;</span>
                    <Grid item>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={loggingIn}
                        >
                            {loggingIn ? 'Loading' : 'Login'}
                        </Button>
                        <br/><br/>
                        <Button variant={'contained'} disabled={loggingIn} onClick={handleResetPasswordButton}>
                            Reset Password
                        </Button>
                    </Grid>
                </FormGroup>
            </Grid>
            <PasswordResetModal
                open={showPasswordResetModal}
                close={closeResetModal}
                handleSubmitReset={handleResetPasswordRequest}
                loading={resettingPassword}
            />
        </Box>
    );
};

Login.propTypes = {
    isMobile: PropTypes.bool,
    setSnackbarProperties: PropTypes.func,
};
export default Login;
