import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, TextField} from '@mui/material';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import SubscriptionApiService from '../../api/SubscriptionApiService';
import * as Constants from '../../utilities/Constants';
import StandardUserEditButton from '../StandardUserFunctions/OptionsButton/StandardUserEditButton';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import SubscriptionDetailPanel from './DetailPanels/SubscriptionDetailPanel';
import {useNavigate} from 'react-router-dom';
import authenticationManager from '../../Auth/AuthenticationManager';
import SubscriptionCardMobile from '../Mobile/SubscriptionCardMobile';

export default function CurrentSubscriptions(props) {
    const { setSnackbarProperties, isMobile } = props;

    const[currentSubscriptions, setCurrentSubscriptions] = useState([]);
    const[currentSelectedSubscription, setCurrentSelectedSubscription] = useState();
    const[showForceCancelSubscriptionDialog, setShowForceCancelSubscriptionDialog] = useState(false);
    const[searchedSubscriptions, setSearchedSubscriptions] = useState([]);
    const[loading, setLoading] = useState(false);
    const[search, setSearch] = useState('');

    const navigate = useNavigate();

    const columns = [
        {
            field: 'appUser.username',
            headerName: 'User',
            flex: 1,
            renderCell: (params) => {
                return `${params.row.appUser.username}`;
            },
            valueGetter: (params) => params.row.appUser.username,
        },
        {
            field: 'appUser.firstName',
            headerName: 'Name',
            flex: .75,
            renderCell: (params) => {
                return `${params.row.appUser.firstName} ${params.row.appUser.lastName}`;
            },
            valueGetter: (params) => `${params.row.appUser.firstName} ${params.row.appUser.lastName}`
        },
        {
            field: 'storageUnit.name',
            headerName: 'Storage Unit',
            flex: 0.5,
            renderCell: (params) => {
                return `${params.row.storageUnit.name}`;
            },
            valueGetter: (params) => params.row.storageUnit.name,
        },
        {
            field: 'storageUnit.price',
            headerName: 'Price',
            flex: 0.4,
            renderCell: (params) => {
                return `${Constants.USDollar.format(params.row.storageUnit.price)}`;
            },
            valueGetter: (params) => params.row.storageUnit.price,
        },
        {
            field: '',
            headerName: '',
            flex: 0.25,
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowForceCancelSubscriptionDialog}
                    setShowEditDialog={() => {return; }}
                    setCurrentSelectedObject={setCurrentSelectedSubscription}
                    params={params}
                    showEditButton={false}
                    deleteButtonText={'Force Cancel'}
                />;
            }
        }
    ];

    const subscriptionApiService = new SubscriptionApiService();

    async function getSubscriptions() {
        setLoading(true);
        const subscriptionsResponse = await subscriptionApiService.getAllSubscriptions();

        if(subscriptionsResponse.success) {
            setCurrentSubscriptions(subscriptionsResponse.data);
            setSearchedSubscriptions(subscriptionsResponse.data);
        } else {
            setSnackbarProperties(`Error retrieving subscriptions: ${subscriptionsResponse.message}`, 'error');
        }
        setLoading(false);
    }

    async function forceCancelSubscription() {
        const deleteResponse = await subscriptionApiService.forceDeleteSubscription(currentSelectedSubscription.id);

        if(deleteResponse.success) {
            setSnackbarProperties('Successfully Deleted Subscription', 'warning');
        } else {
            setSnackbarProperties(`Error deleting subscription: ${deleteResponse.message}`, 'error');
        }

        closeCancelSubscription();
    }

    function closeCancelSubscription() {
        setCurrentSelectedSubscription(undefined);
        setShowForceCancelSubscriptionDialog(false);
    }

    function handleSearchBoxChange(value) {
        setSearch(value);
        if(value.length === 0) {
            setSearchedSubscriptions(currentSubscriptions);
        } else {
            setSearchedSubscriptions(currentSubscriptions.filter((subscription) => subscription.appUser.firstName.toUpperCase().includes(value.toUpperCase()) || subscription.appUser.lastName.toUpperCase().includes(value.toUpperCase()) || subscription.appUser.username.toUpperCase().includes(value.toUpperCase()) || subscription.storageUnit.name.toUpperCase().includes(value.toUpperCase()) || subscription.storageUnit.storageSize.includes(value)));
        }
    }

    const getDetailPanelContent = React.useCallback(({row}) => <SubscriptionDetailPanel row={row} />, []);
    const getDetailPanelHeight = React.useCallback(() => 300, []);

    useEffect(() => {
        if(!authenticationManager.isAuthorized || (!authenticationManager.isAdmin() && !authenticationManager.isSuperAdmin())) {
            navigate('/login');
        }
        document.title = 'Executive Storage Suites - Current Subscriptions';
        getSubscriptions();
    }, []);
    return(
        <Box>
            <h1 style={{marginBottom: '20px'}}>Current Subscriptions</h1>
            {isMobile ?
                <Box>
                    <TextField sx={{width: '100%'}} label={'Search'} value={search} onChange={(event) => handleSearchBoxChange(event.target.value)}/>
                    {searchedSubscriptions.map((subscription, key) => {
                        return <SubscriptionCardMobile
                            key={key}
                            showDeleteSubscriptionDialog={() => { setCurrentSelectedSubscription(subscription); setShowForceCancelSubscriptionDialog(true);}}
                            subscription={subscription}
                        />;})
                    }
                </Box>

                :
                <Box sx={{height: '80vh'}}>
                    <DataGridPro
                        columns={columns}
                        rows={currentSubscriptions}
                        loading={loading}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            Toolbar: {showQuickFilter: true, quickFilterProps: {debounceMs: 500}}
                        }}
                        getRowHeight={() => 'auto'}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                        getRowClassName={(params) => params.row.inGoodStanding ? 'good-standing' : 'bad-standing'}
                    />
                </Box>
            }
            <ConfirmDeleteModal
                open={showForceCancelSubscriptionDialog}
                title={'Force Cancel Subscription'}
                confirmText={`Are you sure you wish to force the cancellation of Subscription for ${currentSelectedSubscription?.appUser.firstName ?? ''} ${currentSelectedSubscription?.appUser.lastName ?? ''} with Unit ${currentSelectedSubscription?.storageUnit.name ?? ''}?`}
                handleConfirm={forceCancelSubscription}
                close={closeCancelSubscription}
                isMobile={isMobile} />
        </Box>
    );
}

CurrentSubscriptions.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
