import React, {useEffect} from 'react';

export default function PrivacyPolicy() {
    const collegeBanner = require('../../resources/css/images/college-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - Privacy Policy';
    }, []);
    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">

                        <article>
                            <header>
                                <h2>Executive Storage Suites Privacy Policy</h2>
                            </header>

                            <span className="image featured"><img src={String(collegeBanner)} alt='' /></span>

                            <p><b>What Information Do You Gather About Me?</b></p>

                            <p>We collect from you only the personal information you voluntarily provide in order to deliver the best possible service. If you choose to process an online payment or reservation we ask you to provide us with information necessary to process your order which may include, but not be limited to, name, physical address, telephone number, facsimile number, email address, company name, title, credit card number, expiration date, and storage unit number.</p>

                            <p>We collect your email address so that we can send you information about your transaction and its status. We may use your email address to inform you about current offers or events. We will never sell individual personal information with anyone without your advance permission or unless ordered by a court of law. The credit card information that is collected is used strictly for billing purposes.</p>

                            <b>Sharing Your Personal Information</b>

                            <p><b>Executive Storage Suites</b> does not sell information to unrelated third parties. <b>Executive Storage Suites</b> does send your contact and order information to the facility that you selected for purposes of satisfying your payment or reservation.</p>

                            <b>Changes to This Online Privacy Policy?</b>

                            <p><b>Executive Storage Suites</b> reserves the right, at its sole discretion, to change, modify, add or remove portions of this Online Privacy Policy at any time. Accordingly, <b>Executive Storage Suites</b> recommends that you revisit this Online Privacy Policy from time to time to ensure that you are aware of the current privacy practices. Your continued use of the website following any changes signifies your acceptance of these changes.</p>

                            <b>Session Cookies</b>

                            <p>The <b>Executive Storage Suites</b> web site, executivestoragesuites.com, uses session cookies to enable making transactions on our site. A session cookie is a small file placed on your computer which acts as a unique identifier to keep track of the information you have entered. Without the session cookie, our site cannot track the information you are entering, and you will be unable to complete your transaction.</p>

                            <p>A session cookie does not remain on your machine after you close your web browser window. Our session cookie contains none of your personal information, and cannot be used to track you around the web. It can only be used by our web site to identify the information you have entered during the transaction process which is required to complete the transaction.</p>

                            <b>How to Enable Cookies on your Browser</b>

                            <p>Your web browser is the primary way you can control cookies. The method for changing your cookie settings will vary depending on the browser you use, but options should be similar regardless of your browser choice.</p>

                            <b>Internet Explorer</b>

                            <p>If you are using IE 6, your cookies will be automatically controlled based on your privacy settings. If you would like to override automatic cookie control, choose Tools -&gt; Internet Options -&gt; Privacy -&gt; Advanced. From this panel you can choose to override automatic cookie handling, and specify how you would like to deal with First Party and Third Party cookies. First party cookies come from the owner of the website you are visiting, third party cookies come from a different company (for instance, and advertising company). You can select “Accept”, “Block”, or “Prompt” – if you choose “Prompt”, your browser will notify you each time a web site attempts to set a cookie on your machine, and you can choose to accept or reject the cookie. If you would like to accept session cookies, but not persistent cookies, check the box to “Always Allow Session Cookies.” Please note that session cookies are required to enable shopping on many web sites, including Long Beach Storage Center.</p>

                            <b>Other Web Browsers</b>

                            <p>Other web browsers provide similar options; if you are using Netscape 7, cookie options can be found at Edit -&gt; Preferences -&gt; Privacy and Security -&gt; Cookies. In Mozilla Firefox, cookie options are available at Options -&gt; Privacy -&gt; Cookies, and in Opera 7 cookie preferences are available in File -&gt; Preferences -&gt; Privacy.</p>

                            <p>You can also control cookies with a number of internet security packages. If you use one of these packages, please refer to the software documentation for more information.</p>

                            <p>This information is provided for reference purposes only and we take no responsibility for any legal ramifications that may result from using all or part of this information. The providing of this information in no way removes your responsibility for your own policies. You will be solely responsible for the information contained in your own refund and privacy policy.</p>

                            <b>Online Privacy Policy</b>

                            <p>We collect from you only the personal information you voluntarily provide in order to deliver the best possible service. If you choose to process an online payment or reservation we ask you to provide us with information necessary to process your order including, but not limited to, name, physical address, telephone number, facsimile number, email address, company name, title credit card number, expiration date, and storage unit number.</p>

                            <p>We collect your email address so that we can send you information about your transaction and its status. The credit card information that is collected is used strictly for billing purposes.</p>

                            <b>Sharing Your Personal Information</b>

                            <p>Long Beach Storage Center here does not sell information to unrelated third parties.</p>

                            <b>Changes to This Online Privacy Policy?</b>

                            <p><b>Executive Storage Suites</b> here reserves the right, at its sole discretion, to change, modify, add or remove portions of this Online Privacy Policy at any time. Accordingly, <b>Executive Storage Suites</b> recommends that you revisit this Online Privacy Policy from time to time to ensure that you are aware of the current privacy practices. Your continued use of the website following any changes signifies your acceptance of these changes.</p>

                        </article>

                    </div>
                </div>
            </section>
        </div>
    );
}
