import React, {useEffect, useState} from 'react';
import {Button, FormGroup} from 'reactstrap';
import Grid from '@mui/material/Grid';
import {Box, FormControl, InputLabel, Select, TextField} from '@mui/material';
import { AppUserDTO } from '../../DTOs/User/AppUserDTO';
import UserApiService from '../../api/UserApiService';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import * as constants from '../../utilities/Constants';
import authenticationManager from '../../Auth/AuthenticationManager';

export default function UserRegistration(props) {
    const {isMobile, isTablet, isDesktop, setSnackbarProperties} = props;

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [driversLicense, setDriversLicense] = useState('');
    const [licenseState, setLicenseState] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [firstnameError, setFirstnameError] = useState(false);
    const [lastnameError, setLastnameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [driversLicenseError, setDriversLicenseError] = useState(false);
    const [licenseStateError, setLicenseStateError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const userApiService = new UserApiService();

    const navigate = useNavigate();

    async function registerUser() {
        const errors = validateInfo();

        if(!errors) {
            const newUser = new AppUserDTO();
            newUser.Username = email;
            newUser.FirstName = firstname;
            newUser.LastName = lastname;
            newUser.Phone = phone;
            newUser.DriversLicenseNumber = driversLicense;
            newUser.LicenseState = licenseState;
            newUser.Address1 = address1;
            newUser.Address2 = address2;
            newUser.City = city;
            newUser.State = state;
            newUser.Zip = zipcode;
            newUser.Password = password;

            const response = await userApiService.registerUser(newUser);

            if(response.success) {
                setSnackbarProperties('Registered Successfully! You may now log in');
                navigate('/');
            } else {
                setSnackbarProperties(`${response}`, 'error');
            }
        }
    }
    function resetErrors() {
        setEmailError(false);
        setFirstnameError(false);
        setLastnameError(false);
        setPhoneError(false);
        setDriversLicenseError(false);
        setLicenseStateError(false);
        setPasswordError(false);
        setConfirmPasswordError(false);
    }

    function validateInfo() {
        resetErrors();
        let errorMessage = '';
        if(!email) {
            errorMessage = 'Email is required';
        }

        setEmailError(!email);

        if(!firstname) {
            errorMessage += errorMessage === '' ? 'First Name is required' : ', First Name is required';
        }

        setFirstnameError(!firstname);

        if(!lastname) {
            errorMessage += errorMessage === '' ? 'Last Name is required' : ', Last Name is required';
        }

        setLastnameError(!lastname);

        if(!phone) {
            errorMessage += errorMessage === '' ? 'Phone # is required' : ', Phone # is required';
        }

        setPhoneError(!phone);

        if(!driversLicense) {
            errorMessage += errorMessage === '' ? 'Driver\'s License is required' : ', Driver\'s License is required';
        }

        setDriversLicenseError(!driversLicense);

        if(!licenseState) {
            errorMessage += errorMessage === '' ? 'License State is required' : ', License State is required';
        }

        setLicenseStateError(!licenseState);

        if(!password) {
            errorMessage += errorMessage === '' ? 'Password is required' : ', Password is required';
        }

        setPasswordError(!password);

        if(!confirmPassword) {
            errorMessage += errorMessage === '' ? 'You must confirm the password' : ', You must confirm the password';
        }

        setConfirmPasswordError(!confirmPassword);

        if(password !== confirmPassword) {
            errorMessage += errorMessage === '' ? 'Passwords must match' : ', Passwords much match';
            setPasswordError(true);
            setConfirmPasswordError(true);
        }

        if(errorMessage.length > 0) {
            setSnackbarProperties(errorMessage, 'error');
        }

        return errorMessage.length > 0;

    }

    function handleStateChange(event) {
        setLicenseState(event.target.value);
    }

    const mobileSx = {width: '100%'};
    const desktopSx = {width: '35%', display : 'flex'};

    useEffect(() => {
        if(authenticationManager.isAuthorized) {
            navigate('/user-info');
            setSnackbarProperties('You are already logged in', 'warning');
        }
        document.title = 'Executive Storage Suites - Register';
    }, []);


    return(
        <Box sx={{ width: '100%'}}>
            <center><h3>Register User</h3></center>
            {(isMobile || isTablet) &&
                <FormGroup>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <TextField
                                sx={mobileSx}
                                error={emailError}
                                type='text'
                                label='E-mail*'
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Grid>
                        <Grid item className='col-12'>
                            <TextField
                                sx={mobileSx}
                                error={firstnameError}
                                type='text'
                                id='firstName'
                                label='First Name*'
                                value={firstname} onChange={(event) => setFirstname(event.target.value)}
                            />
                        </Grid>
                        <Grid item className='col-12'>
                            <TextField
                                sx={mobileSx}
                                error={lastnameError}
                                type='text'
                                id='lastName'
                                label="Last Name*"
                                value={lastname}
                                onChange={(event) => setLastname(event.target.value)}
                            />
                        </Grid>
                        <Grid item className='col-12'>
                            <TextField
                                sx={mobileSx}
                                error={phoneError}
                                type='text'
                                id='phone'
                                label="Phone*"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                            />
                        </Grid>
                        <Grid item className='col-12'>
                            <TextField
                                sx={mobileSx}
                                type='text'
                                error={driversLicenseError}
                                id='driverLicense'
                                label="Driver's License*" value={driversLicense}
                                onChange={(event) => setDriversLicense(event.target.value)}
                            />
                        </Grid>
                        <Grid item className='col-12'>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id={'license-state-select-label'}>License State*</InputLabel>
                                <Select
                                    labelId={'license-state-select-label'}
                                    error={licenseStateError}
                                    id='license-state-select'
                                    label="License State*"
                                    value={licenseState}
                                    onChange={handleStateChange}
                                >
                                    {constants.driversLicenseStates.map((state, index) =>
                                        <MenuItem key={index} value={state}>{state}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className={'col-12'}>
                            <TextField
                                sx={mobileSx}
                                label={'Address Line 1'}
                                value={address1}
                                onChange={(event) => setAddress1(event.target.value)}
                            />
                        </Grid>
                        <Grid item className={'col-12'}>
                            <TextField
                                sx={mobileSx}
                                label={'Address Line 2'}
                                value={address2}
                                onChange={(event) => setAddress2(event.target.value)}
                            />
                        </Grid>
                        <Grid item className={'col-12'}>
                            <TextField
                                sx={mobileSx}
                                label={'City'}
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                            />
                        </Grid>
                        <Grid item className={'col-12'}>
                            <TextField
                                sx={mobileSx}
                                label={'State'}
                                value={state}
                                onChange={(event) => setState(event.target.value)}
                            />
                        </Grid>
                        <Grid item className={'col-12'}>
                            <TextField
                                sx={mobileSx}
                                label={'Zip Code'}
                                value={zipcode}
                                onChange={(event) => setZipcode(event.target.value)}
                            />
                        </Grid>
                        <Grid item className="col-12">
                            <TextField
                                sx={mobileSx}
                                error={passwordError}
                                type={'password'}
                                id='password'
                                label='Password*'
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </Grid>
                        <Grid item className="col-12">
                            <TextField
                                sx={mobileSx}
                                error={confirmPasswordError}
                                type={'password'}
                                id='verifyPassword'
                                label='Verify Password*'
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                            />
                        </Grid>
                        <Grid item className="col-12">
                            <Button type="contained" style={{width: '100%'}} onClick={registerUser}>Register</Button>
                        </Grid>
                    </Grid>
                </FormGroup> }
            {isDesktop &&
                <FormGroup>
                    <center>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <TextField
                                    sx={desktopSx}
                                    error={emailError}
                                    type='text'
                                    label='E-mail*'
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={desktopSx}
                                    error={firstnameError}
                                    type='text'
                                    id='firstName'
                                    label='First Name*'
                                    value={firstname} onChange={(event) => setFirstname(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={desktopSx}
                                    error={lastnameError}
                                    type='text'
                                    id='lastName'
                                    label="Last Name*"
                                    value={lastname}
                                    onChange={(event) => setLastname(event.target.value)}
                                />
                            </Grid>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={desktopSx}
                                    error={phoneError}
                                    type='text'
                                    id='phone'
                                    label="Phone*"
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                />
                            </Grid>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={desktopSx}
                                    type='text'
                                    error={driversLicenseError}
                                    id='driverLicense'
                                    label="Driver's License*" value={driversLicense}
                                    onChange={(event) => setDriversLicense(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <FormControl sx={desktopSx}>
                                    <InputLabel id={'license-state-select-label'}>License State*</InputLabel>
                                    <Select
                                        labelId={'license-state-select-label'}
                                        error={licenseStateError}
                                        type='text'
                                        id='license-state-select'
                                        label="License State*"
                                        value={licenseState}
                                        onChange={handleStateChange}
                                    >
                                        {constants.driversLicenseStates.map((state, index) =>
                                            <MenuItem key={index} value={state}>{state}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={desktopSx}
                                    label={'Address Line 1'}
                                    value={address1}
                                    onChange={(event) => setAddress1(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={desktopSx}
                                    label={'Address Line 2'}
                                    value={address2}
                                    onChange={(event) => setAddress2(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={desktopSx}
                                    label={'City'}
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={desktopSx}
                                    label={'State'}
                                    value={state}
                                    onChange={(event) => setState(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={desktopSx}
                                    label={'Zip Code'}
                                    value={zipcode}
                                    onChange={(event) => setZipcode(event.target.value)}
                                />
                            </Grid>
                            <Grid item className="col-12">
                                <TextField
                                    sx={desktopSx}
                                    error={passwordError}
                                    type={'password'}
                                    id='password'
                                    label='Password*'
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </Grid>
                            <Grid item className="col-12">
                                <TextField
                                    sx={desktopSx}
                                    error={confirmPasswordError}
                                    type={'password'}
                                    id='verifyPassword'
                                    label='Verify Password*'
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                />
                            </Grid>
                            <Grid item className="col-12">
                                <Button style={{width: '35%'}} type="contained" onClick={registerUser}>Register</Button>
                            </Grid>
                        </Grid>
                    </center>
                </FormGroup>
            }
        </Box>);
}

UserRegistration.propTypes = {
    isMobile: PropTypes.bool,
    setSnackbarProperties: PropTypes.func,
    isTablet: PropTypes.bool,
    isDesktop: PropTypes.bool,
};
