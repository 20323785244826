import React from 'react';
import PropTypes from 'prop-types';

export default function Header({companyName, companySite}) {

    return(
        <div id={'header'}>
            <h1>
                <a id={'logo'} href={companySite}>{companyName}</a>
            </h1>
        </div>
    );
}

Header.propTypes = {
    companyName: PropTypes.string.isRequired,
    companySite: PropTypes.string.isRequired,
};
