import React, { useState } from 'react';
import TextInput from './BaseInput/TextInput';
import PropTypes from 'prop-types';
import ContactFormDTO from '../DTOs/ContactFormDTO';
import ContactFormApiService from '../api/ContactFormApiService';

const Footer = ({companyName}) => {
    const[contactFormName, setContactFormName] = useState();
    const[contactFormEmail, setContactFormEmail] = useState();
    const[contactFormMessage, setContactFormMessage] = useState();
    const[nameErrorMessage, setNameErrorMessage] = useState();
    const[emailErrorMessage, setEmailErrorMessage] = useState();
    const[messageErrorMessage, setMessageErrorMessage] = useState();
    const[error, setError] = useState(false);
    const[successContact, setSuccessContact] = useState(false);

    const contactFormApiService = new ContactFormApiService();

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        validateForm();

        if(!error) {
            const messageRequest = new ContactFormDTO();

            messageRequest.name = contactFormName;
            messageRequest.email = contactFormEmail;
            messageRequest.message = contactFormMessage;

            const contactResult = await contactFormApiService.sendContactForm(messageRequest);
            if(contactResult.success) {
                setContactFormName('');
                setContactFormEmail('');
                setContactFormMessage('');
                setSuccessContact(true);
            } else {
                setSuccessContact(false);
            }
        }
    };

    function validateForm() {
        let hasError = false;
        if(contactFormName === null || contactFormName === undefined || contactFormName === '') {
            setNameErrorMessage('Name is required');
            hasError = true;
        } else {
            setNameErrorMessage('');
        }

        if(contactFormEmail === null || contactFormEmail === undefined || contactFormEmail === '') {
            setEmailErrorMessage('Email is required');
            hasError = true;
        } else {
            setEmailErrorMessage('');
        }

        if(contactFormMessage === null || contactFormMessage === undefined || contactFormMessage === '') {
            setMessageErrorMessage('Name is required');
            hasError = true;
        } else {
            setMessageErrorMessage('');
        }

        setError(hasError);

        return hasError;
    }

    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <section className="col-3 col-6-narrower col-12-mobilep">
                        <h3>Quick Links</h3>
                        <ul className="links">
                            <li><a href="https://essdocuments.blob.core.windows.net/lease-docs/lease.pdf"
                                target="_blank" rel="noreferrer">Lease</a></li>
                            <li><a href="/tips">Storage Tips</a></li>
                            <li><a href="/terms">Terms & Conditions</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                        </ul>
                    </section>
                    <section className="col-3 col-6-narrower col-12-mobilep">
                        <h3>Site Map</h3>
                        <ul className="links">
                            <li><a href="/">Home</a></li>
                            <li><a href="/available-units">Units Available</a></li>
                            <li><a className={'page-link'} href="/features">Features</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/college">College Storage</a></li>
                            <li><a href="/calculator">Space Calculator</a>
                            </li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                    </section>
                    <section className="col-6 col-12-narrower">
                        <h3>Get In Touch</h3>
                        {successContact && <span style={{color:'green'}}>Successfully submitted contact form!</span>}

                        <form id="contact-form" role="form" onSubmit={handleSubmit}>
                            <div className="row gtr-50">
                                <div className="col-6 col-12-mobilep">
                                    <TextInput
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        errorMessage={nameErrorMessage}
                                        value={contactFormName}
                                        handleChange={(event) => setContactFormName(event.target.value)}
                                    />
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <TextInput
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        errorMessage={emailErrorMessage}
                                        value={contactFormEmail}
                                        handleChange={(event) => setContactFormEmail(event.target.value)}
                                    />
                                </div>
                                <div className="col-12">
                                    <TextInput
                                        isTextArea={true}
                                        name="message"
                                        id="message"
                                        placeholder="Message"
                                        rows={5}
                                        errorMessage={messageErrorMessage}
                                        value={contactFormMessage}
                                        handleChange={(event) => setContactFormMessage(event.target.value)}
                                    />
                                </div>
                                <div className="col-12">
                                    <ul className="actions">
                                        <li><input type="submit" className="button alt" value="Send Message"/></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <p></p>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>

            <div className="copyright">
                <ul className="menu">
                    <li>&copy; {companyName}</li>
                    <li>Design: <a href="https://www.bjk.x10host.com">Bradley James Kroll</a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;

Footer.propTypes = {
    companyName: PropTypes.string.isRequired,
};
