import React, { useState, useEffect, useMemo } from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './resources/css/custom.css';
import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey('5a9d8cffa27a9ade40c567bedb8a99b4Tz03NTM2OCxFPTE3MjcxODI0MDgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


const vertical = 'top';
const horizontal = 'center';
const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function App() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = useMemo(() => width <= 768, [width]);
    const isTablet = useMemo(() => width > 768 && width <= 1280, [width]);
    const isDesktop = useMemo(() => !isMobile && !isTablet, [isMobile, isTablet]);

    useEffect(() => {
        const jquery = document.createElement('script');
        jquery.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js';
        jquery.async = true;

        const bootstrap = document.createElement('script');
        bootstrap.src = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js';
        bootstrap.async = true;

        const popper = document.createElement('script');
        popper.src = 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js';
        popper.async = true;

        document.body.appendChild(jquery);
        document.body.appendChild(bootstrap);
        document.body.appendChild(popper);

        window.addEventListener('resize', handleWindowSizeChange);
        return() => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    function resetSnackbar () {
        setSnackbarMessage('');
        setSnackbarSeverity('success');
    }

    function closeSnackbar (event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    }

    function setSnackbarProperties (message, severity) {
        setSnackbarMessage(message ?? '');
        setSnackbarSeverity(severity ?? 'success');
        setSnackbarOpen(true);
    }


    return (
        <>
            <Layout isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={React.cloneElement(element, { setSnackbarProperties, isMobile, isTablet, isDesktop })}/>;
                    })}
                </Routes>
            </Layout>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                key={'topcenter'}
                anchorOrigin={{ vertical, horizontal }}
                onClose={closeSnackbar}
                TransitionProps={{
                    onExited: () => {
                        resetSnackbar();
                    }
                }}
            >
                <Alert
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
