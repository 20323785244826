import apiService from './ApiService';

export default class PaymentLogApiService {

    /**
     * Gets Payment log transactions between specified dates
     * @param fromDate {Dayjs}
     * @param toDate {Dayjs}
     * @return {Promise<ServiceResponse<Array<PaymentLogDTO>>>}
     */
    async getPaymentLogs(fromDate, toDate) {
        const response = await apiService.getData(`api/PaymentLog/from/${fromDate.month() + 1}/${fromDate.date()}/${fromDate.year()}/to/${toDate.month() + 1}/${toDate.date() + 1}/${toDate.year()}`);

        return response.data;
    }
}
