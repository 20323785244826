import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import SubscriptionDTO from '../../DTOs/Subscription/SubscriptionDTO';
import {Button, Label} from 'reactstrap';
import {USDollar} from '../../utilities/Constants';

export default function SubscriptionCardMobile(props) {
    const { key, subscription, showDeleteSubscriptionDialog } = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`User: ${subscription.appUser.firstName} ${subscription.appUser.lastName} (${subscription.appUser.username})`}</Label>
            <Label>{`Phone #: ${subscription.appUser.phone}`}</Label>
            <Label>{`Door Code: ${subscription.appUser.doorCode}`}</Label>
            <Label>{`Driver's License Number: ${subscription.appUser.driversLicenseNumber}`}</Label>
            <Label>{`Storage Unit: ${subscription.storageUnit.name}`}</Label>
            <Label>{`Price: ${USDollar.format(subscription.storageUnit.price)}`}</Label>
            <Label>{`Storage Unit Size: ${subscription.storageUnit.storageSize}`}</Label>
            <Label>{`Payment Option: ${subscription.paymentOption.name}`}</Label>
            <Label>{`In Good Standing? ${subscription.inGoodStanding ? 'Yes' : 'No'}`}</Label>
            <Button className={'mobile-button'} color={'danger'} onClick={showDeleteSubscriptionDialog}>Force Cancel Subscription</Button>
        </Box>
    );
}

SubscriptionCardMobile.propTypes = {
    key: PropTypes.number.isRequired,
    subscription: PropTypes.objectOf(SubscriptionDTO).isRequired,
    showDeleteSubscriptionDialog: PropTypes.func.isRequired,
};
