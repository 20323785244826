import apiService from './ApiService';

export default class SectionApiService {

    /**
     * Gets all sections
     * @return {Promise<ServiceResponse<Array<SectionDTO>>>}
     */
    async getSections() {
        const sections = await apiService.getData('api/admin/section');

        return sections.data;
    }
}
