import React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { Button, Label } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/material';


export default function ConfirmDeleteModal(props) {
    const { open, title, confirmText, handleConfirm, close, isMobile } = props;

    return (
        <Dialog
            closeAfterTransition={true}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth="sm"
            fullWidth
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Label>{confirmText}</Label>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className={isMobile ? 'mobile-modal-button' : ''} autoFocus variant='contained' onClick={close}>
                    Cancel
                </Button>
                <Button className={isMobile ? 'mobile-modal-button' : ''} color={'danger'} onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDeleteModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
};
