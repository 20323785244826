import React, {useEffect, useState} from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from './Footer';
import PropTypes from 'prop-types';
import SettingsApiService from '../api/SettingsApiService';
import Header from './Header';

export function Layout({children, isMobile, isTablet, isDesktop}) {
    const[companyName, setCompanyName] = useState('');
    const[companySite, setCompanySite] = useState('');

    const settingsApiService = new SettingsApiService();


    useEffect(() => {
        const fetchData = async () =>
        {
            const companyNameSetting = await settingsApiService.getSiteName();
            const companySiteSetting = await settingsApiService.getCompanyWebsite();
            setCompanyName(companyNameSetting.value);
            setCompanySite(companySiteSetting.value);
        };

        fetchData()
            .catch(console.error);
    }, []);

    return (
        <div>
            <Header
                companyName={companyName}
                companySite={companySite}
            />
            <NavMenu
                companyName={companyName}
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
            />
            <Container tag="main">
                {children}
            </Container>
            <Footer
                companyName={companyName}
            />
        </div>
    );

}

Layout.propTypes = {
    children: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired,
};
