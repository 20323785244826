import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import StandardUserEditButton from '../StandardUserFunctions/OptionsButton/StandardUserEditButton';
import StorageUnitManagementApiService from '../../api/StorageUnitManagementApiService';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import EditStorageUnitModal from '../Modal/AdminFunctions/EditStorageUnitModal';
import {Button} from 'reactstrap';
import StorageUnitMaintenanceDTO from '../../DTOs/StorageUnit/StorageUnitMaintenanceDTO';
import FacilityApiService from '../../api/FacilityApiService';
import SectionApiService from '../../api/SectionApiService';
import * as Constants from '../../utilities/Constants';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import StorageUnitMaintenanceCardMobile from '../Mobile/StorageUnitMaintenanceCardMobile';

export default function StorageUnitMaintenance(props) {
    const { setSnackbarProperties, isMobile, isTablet } = props;

    const[storageUnits, setStorageUnits] = useState([]);
    const[showEditStorageUnit, setShowEditStorageUnit] = useState(false);
    const[showDeleteStorageUnit, setShowDeleteStorageUnit] = useState(false);
    const[loading, setLoading] = useState(false);
    const[selectedStorageUnit, setSelectedStorageUnit] = useState(new StorageUnitMaintenanceDTO());
    const[facilities, setFacilities] = useState([]);
    const[sections, setSections] = useState([]);
    const[storageTypes, setStorageTypes] = useState([]);

    const storageUnitManagementApiService = new StorageUnitManagementApiService();

    const facilityApiService = new FacilityApiService();

    const sectionApiService = new SectionApiService();

    const navigate = useNavigate();

    const columnsDesktop= [
        {
            field: 'name',
            headerName: 'Storage Unit',
            flex: 1,
        },
        {
            field: 'currentUser.username',
            headerName: 'Current User',
            renderCell: (params) => {
                return params.row.currentUser?.username ?? '';
            },
            flex: 2,
        },
        {
            field: 'section.name',
            headerName: 'Section',
            flex: 0.5,
            renderCell: (params) => {
                return params.row.section.name;
            }
        },
        {
            field: 'facility.name',
            headerName: 'Facility',
            flex: 1,
            renderCell: (params) => {
                return params.row.facility.name;
            }
        },
        {
            field: 'storageSize',
            headerName: 'Storage Size',
            flex: 1,
        },
        {
            field: 'storageUnitType',
            headerName: 'Storage Type',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnitType.name;
            }
        },
        {
            field: 'climateControlled',
            headerName: 'Climate Controlled',
            renderCell: (params) => {
                return params.row.climateControlled ? 'Yes' : 'No';
            }
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => {
                return Constants.USDollar.format(params.row.price);
            }
        },
        {
            field: 'nextReadyDate',
            headerName: 'Cancellation Date',
            flex: 1,
            renderCell: (params) => {
                return params.row.nextReadyDate ?? '';
            }
        },
        {
            field: 'hold',
            headerName: 'Hold?',
            flex: 0.5,
            renderCell: (params) => {
                return params.row.hold ? 'Yes' : 'No';
            }
        },
        {
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowDeleteStorageUnit}
                    setShowEditDialog={setShowEditStorageUnit}
                    setCurrentSelectedObject={setSelectedStorageUnit}
                    params={params}
                    editButtonText={'Edit Storage Unit'}
                    deleteButtonText={'Delete Storage Unit'}
                />;
            }
        }
    ];

    const columnsTablet= [
        {
            field: 'name',
            headerName: 'Storage Unit',
            flex: 1,
        },
        {
            field: 'storageSize',
            headerName: 'Storage Size',
            flex: 1,
        },
        {
            field: 'storageUnitType',
            headerName: 'Storage Type',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnitType.name;
            }
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => {
                return Constants.USDollar.format(params.row.price);
            }
        },
        {
            headerName: 'Options',
            flex: 0.75,
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowDeleteStorageUnit}
                    setShowEditDialog={setShowEditStorageUnit}
                    setCurrentSelectedObject={setSelectedStorageUnit}
                    params={params}
                    editButtonText={'Edit Storage Unit'}
                    deleteButtonText={'Delete Storage Unit'}
                />;
            }
        }
    ];

    async function editStorageUnit() {
        if(selectedStorageUnit.id > 0) {
            const response = await storageUnitManagementApiService.updateStorageUnit(selectedStorageUnit);
            if(response.success) {
                setSnackbarProperties('Updated Storage Unit Successfully');
            } else {
                setSnackbarProperties(`Error Updating Storage Unit: ${response.message}`, 'error');
            }
        } else {
            const addResponse = await storageUnitManagementApiService.addStorageUnit(selectedStorageUnit);

            if(addResponse.success) {
                setSnackbarProperties('Add Storage Unit Successfully');
            } else {
                setSnackbarProperties(`Error adding Storage Unit: ${addResponse.message}`, 'error');
            }
        }
        closeEditModal();
    }

    function closeEditModal() {
        setShowEditStorageUnit(false);
        setSelectedStorageUnit(new StorageUnitMaintenanceDTO());
    }

    async function deleteStorageUnit() {
        const response = await storageUnitManagementApiService.deleteStorageUnit(selectedStorageUnit);
        if(response) {
            setSnackbarProperties('Successfully Deleted Storage Unit', 'warning');
            closeDelete();
        } else {
            setSnackbarProperties(`Error Deleting Storage Unit: ${response.message}`, 'error');
        }
    }

    function closeDelete() {
        setSelectedStorageUnit(undefined);
        setShowDeleteStorageUnit(false);
    }

    function handleAddNewStorageUnit() {
        setSelectedStorageUnit(new StorageUnitMaintenanceDTO());
        setShowEditStorageUnit(true);
    }

    async function loadData() {
        setLoading(true);

        await getStorageUnits();
        await getFacilities();
        await getSections();
        await getStorageTypes();

        setLoading(false);
    }

    async function getStorageUnits() {
        const storageUnits = await storageUnitManagementApiService.getAllStorageUnits();

        if(storageUnits.success) {
            setStorageUnits(storageUnits.data);
        } else {
            setSnackbarProperties(`Error getting storage units: ${storageUnits.message}`, 'error');
        }
    }

    async function getFacilities() {
        const facilitiesResponse = await facilityApiService.getFacilities();

        if(facilitiesResponse.success) {
            setFacilities(facilitiesResponse.data);
        } else {
            setSnackbarProperties(`Error getting Facilities: ${facilitiesResponse.message}`, 'error');
        }
    }

    async function getSections() {
        const sectionResponse = await sectionApiService.getSections();

        if(sectionResponse.success) {
            setSections(sectionResponse.data);
        } else {
            setSnackbarProperties(`Error getting Sections: ${sectionResponse.message}`, 'error');
        }
    }

    async function getStorageTypes() {
        const storageTypeResponse = await storageUnitManagementApiService.getStorageUnitTypes();

        if(storageTypeResponse.success) {
            setStorageTypes(storageTypeResponse.data);
        } else {
            setSnackbarProperties(`Error getting Storage Types: ${storageTypeResponse.message}`, 'error');
        }
    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized || (!authenticationManager.isAdmin() && !authenticationManager.isSuperAdmin())) {
            navigate('/login');
        }
        document.title = 'Executive Storage Suites - Admin - Configure Storage Units';
        loadData();
    }, []);

    return(
        <Box>
            {isMobile ?
                storageUnits.map((storageUnit, key) => {
                    return <StorageUnitMaintenanceCardMobile
                        storageUnit={storageUnit}
                        key={key}
                        showEditModal={() => {setSelectedStorageUnit(storageUnit); setShowEditStorageUnit(true);}}
                        showDeleteModal={() => {setSelectedStorageUnit(storageUnit); setShowDeleteStorageUnit(true);}}
                    />;
                })
                :
                <Box sx={{height: '80vh'}}>
                    <h1>Storage Unit Admin</h1>
                    <Button onClick={() => handleAddNewStorageUnit()}>Add Storage Unit</Button>
                    <DataGrid
                        columns={isTablet ? columnsTablet : columnsDesktop}
                        rows={storageUnits}
                        getRowHeight={() => 'auto'}
                        loading={loading}
                    />
                </Box>
            }
            <ConfirmDeleteModal
                open={showDeleteStorageUnit}
                title={'Delete Storage Unit'}
                confirmText={'Are you sure you want to delete this storage unit?'}
                handleConfirm={deleteStorageUnit}
                close={closeDelete}
                isMobile={isMobile}
            />
            <EditStorageUnitModal
                isMobile={isMobile}
                open={showEditStorageUnit}
                handleSave={editStorageUnit}
                storageUnit={selectedStorageUnit}
                facilities={facilities}
                sections={sections}
                storageTypes={storageTypes}
                setStorageUnit={setSelectedStorageUnit}
                close={closeEditModal}
            />
        </Box>

    );
}

StorageUnitMaintenance.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
};
