import apiService from './ApiService';

export default class UserApiService {

    /**
     * Register a new user
     * @param newUserInfo {AppUserDTO}
     * @returns {Promise<ServiceResponse<number>>}
     */
    async registerUser(newUserInfo) {
        const response = await apiService.postData('/api/Auth/register', newUserInfo);

        return response.data;
    }

    /**
     * Get all the user's information
     * @param userId {number}
     * @return {Promise<ServiceResponse<AppUserGenericDTO>>}
     */
    async getUserInfo(userId) {
        const response = await apiService.getData('/api/User/' + userId, null);
        return response.data;
    }

    /**
     * Update a user
     * @param userDto {AppUserDTO}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async updateUserInfo(userDto) {
        const response = await apiService.putData('api/User/', userDto);

        return response.data;
    }

    async getAllUsersGeneric() {
        const response = await apiService.getData('api/User/admin/generic');

        return response.data;
    }
}
