import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import StorageUnitMaintenanceDTO from '../../../DTOs/StorageUnit/StorageUnitMaintenanceDTO';
import DialogActions from '@mui/material/DialogActions';
import {Button, Label} from 'reactstrap';
import {Checkbox, Select, TextField} from '@mui/material';
import StorageUnitTypeDTO from '../../../DTOs/StorageUnit/StorageUnitTypeDTO';
import SectionDTO from '../../../DTOs/StorageUnit/SectionDTO';
import FacilityDTO from '../../../DTOs/StorageUnit/FacilityDTO';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import DialogContent from '@mui/material/DialogContent';

const NAME_FIELD = 'name';
const SECTION_FIELD = 'section';
const STORAGE_SIZE_FIELD = 'storageSize';
const PRICE_FIELD = 'price';
const FACILITY_FIELD = 'facility';
const STORAGE_TYPE_FIELD = 'storageType';
const CLIMATE_CONTROLLED_FIELD = 'climateControlled';

export default function EditStorageUnitModal(props) {
    const { isMobile, open, storageUnit, setStorageUnit, handleSave, facilities, storageTypes, sections, close } = props;

    function setStorageUnitField(event, fieldName) {
        const copyStorageUnit = {...storageUnit};

        switch (fieldName) {
        case NAME_FIELD:
            copyStorageUnit.name = event.target.value;
            break;
        case SECTION_FIELD:
            copyStorageUnit.sectionId = event.target.value;
            break;
        case STORAGE_SIZE_FIELD:
            copyStorageUnit.storageSize = event.target.value;
            break;
        case PRICE_FIELD:
            copyStorageUnit.price = event.target.value;
            break;
        case FACILITY_FIELD:
            copyStorageUnit.facilityId = event.target.value;
            break;
        case STORAGE_TYPE_FIELD:
            copyStorageUnit.storageUnitTypeId = event.target.value;
            break;
        case CLIMATE_CONTROLLED_FIELD:
            copyStorageUnit.climateControlled = event.target.checked;
            break;
        default:
            console.log('error');
        }

        setStorageUnit(copyStorageUnit);
    }

    useEffect(() => {

    }, [open]);

    return(
        <div>
            <Dialog
                open={open}
                closeAfterTransition={true}
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {storageUnit?.id ? `Edit Storage Unit ${storageUnit?.name}` : 'Add New Storage Unit' }
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container >
                        <Grid item xs={12} sm={6}>
                            <Label>Name:</Label>
                            <TextField sx={{width: '90%'}} value={storageUnit?.name} onChange={(event) => setStorageUnitField(event, NAME_FIELD)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label>Section:</Label>
                            <Select
                                sx={{width: '90%'}}
                                value={ storageUnit?.sectionId }
                                onChange={(event) => setStorageUnitField(event, SECTION_FIELD)}
                            >
                                {sections.map((section, index) =>
                                    (<MenuItem key={index} value={section.id}>{section.name}</MenuItem>)
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label>Storage Size:</Label>
                            <TextField sx={{width: '90%'}} value={storageUnit?.storageSize} onChange={(event) => setStorageUnitField(event, STORAGE_SIZE_FIELD)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label>Price:</Label>
                            <TextField sx={{width: '90%'}} type={'number'} value={storageUnit?.price} onClick={(event) => setStorageUnitField(event, PRICE_FIELD)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label>Facility:</Label>
                            <Select
                                sx={{width: '90%'}}
                                value={facilities.length > 0 ? storageUnit?.facilityId : 0}
                                onChange={(event) => setStorageUnitField(event, FACILITY_FIELD)} >
                                {facilities.map((facility, index) => <MenuItem key={index} value={facility.id}>{facility.name}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label>Storage Unit Type:</Label>
                            <Select
                                sx={{width: '90%'}}
                                value={storageTypes.length > 0 ? storageUnit?.storageUnitTypeId : 0}
                                onChange={(event) => setStorageUnitField(event, STORAGE_TYPE_FIELD)}
                            >
                                {storageTypes.map((type, index) => <MenuItem key={index} value={type.id}>{type.name}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Label>Climate Controlled</Label>
                            <Checkbox
                                checked={storageUnit?.climateControlled}
                                onChange={(event) => setStorageUnitField(event, CLIMATE_CONTROLLED_FIELD)}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button className={isMobile ? 'mobile-modal-button' : ''} autoFocus variant='contained' onClick={close}>
                        Cancel
                    </Button>
                    <Button className={isMobile ? 'mobile-modal-button' : ''} color={'danger'} onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

EditStorageUnitModal.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    storageUnit: PropTypes.objectOf(StorageUnitMaintenanceDTO),
    handleSave: PropTypes.func.isRequired,
    setStorageUnit: PropTypes.func.isRequired,
    facilities: PropTypes.arrayOf(FacilityDTO).isRequired,
    sections: PropTypes.arrayOf(SectionDTO).isRequired,
    storageTypes: PropTypes.arrayOf(StorageUnitTypeDTO).isRequired,
    close: PropTypes.func.isRequired,
};
