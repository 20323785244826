import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import StandardUserEditButton from '../StandardUserFunctions/OptionsButton/StandardUserEditButton';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import PropTypes from 'prop-types';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import SubscriptionApiService from '../../api/SubscriptionApiService';
import {USDollar} from '../../utilities/Constants';
import ApprovalsPendingPaymentCardMobile from '../Mobile/ApprovalsPendingPaymentCardMobile';
import {Label} from 'reactstrap';

export default function ApprovalsPendingPayment(props) {

    const {isMobile, setSnackbarProperties} = props;
    const[pendingPayments, setPendingPayments] = useState([]);
    const[showDeleteDialog, setShowDeleteDialog] = useState(false);
    const[currentSelectedPendingPayment, setCurrentSelectedPendingPayment] = useState();

    const subscriptionApiService = new SubscriptionApiService();
    const navigate = useNavigate();

    function closeDelete() {
        setCurrentSelectedPendingPayment(undefined);
        setShowDeleteDialog(false);
    }

    async function revokeApproval() {
        const response = await subscriptionApiService.revokeApproval(currentSelectedPendingPayment.token);

        if(response.success) {
            setSnackbarProperties('Revoked Approval');
        } else if(response.message) {
            setSnackbarProperties(`Error Revoking Approval: ${response.message}`, 'error');
        } else {
            setSnackbarProperties(`Error Revoking Approval: ${response}`, 'error');
        }

        closeDelete();
    }

    async function loadPendingPayments() {
        const response = await subscriptionApiService.getApprovalsPendingPayment();

        if(response.success) {
            setPendingPayments(response.data);
        } else if(response.message) {
            setSnackbarProperties(`Error Loading Approvals: ${response.message}`, 'error');
        } else {
            setSnackbarProperties(`Error Loading Approvals: ${response}`, 'error');
        }

    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized) {
            navigate('/');
        }

        document.title = 'Executive Storage Suites - Approvals Pending Payment';
        loadPendingPayments();
    }, [showDeleteDialog]);

    const columns = [
        {
            field: 'storageUnit.name',
            headerName: 'Storage Unit',
            renderCell: (params) => {
                return `${params.row.storageUnit.name}`;
            },
            flex: 1,
        },
        {
            field: 'appUser.firstName',
            headerName: 'Requesting User',
            renderCell: (params) => {
                return `${params.row.appUser.firstName} ${params.row.appUser.lastName} (${params.row.appUser.username})`;
            },
            flex: 2,
        },
        {
            field: 'storageUnit.price',
            headerName: 'Price',
            renderCell: (params) => {
                return USDollar.format(params.row.storageUnit.price);
            },
            flex: 1,
        },
        {
            field: 'moveInDate',
            headerName: 'Move In Date',
            renderCell: (params) => {
                return params.row.moveInDate.toString().substring(0, params.row.moveInDate.toString().length - 9);
            },
            flex: 1,
        },
        {
            field: 'token',
            headerName: 'Token',
            renderCell: (params) => {
                return params.row.token;
            },
            flex: 1,
        },
        {
            field: '',
            headerName: '',
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowDeleteDialog}
                    setShowEditDialog={() => {}}
                    setCurrentSelectedObject={setCurrentSelectedPendingPayment}
                    params={params}
                    showEditButton={false}
                    deleteButtonText={'Revoke Approval'}
                />;
            },
        }
    ];

    return(
        <Box>
            <h1>Approvals Pending Payment</h1>
            {isMobile ? pendingPayments.length > 0 ?
                pendingPayments.map((pendingPayment, index) => {
                    return <ApprovalsPendingPaymentCardMobile
                        approvalPendingPayment={pendingPayment}
                        showRevoke={() => { setCurrentSelectedPendingPayment(pendingPayment); setShowDeleteDialog(true);}}
                        key={index}
                    />;
                }) : <Label>There are no Approvals Pending Payment</Label>
                :
                <Box sx={{height: '80vh'}}>
                    <DataGrid
                        columns={columns}
                        rows={pendingPayments}
                        getRowId={(row) => row.token}
                    />

                </Box>
            }
            <ConfirmDeleteModal
                open={showDeleteDialog}
                title={'Revoke Approval'}
                confirmText={`Are you sure you wish to revoke this approval for ${currentSelectedPendingPayment?.appUser.firstName} ${currentSelectedPendingPayment?.appUser.lastName}?`}
                handleConfirm={revokeApproval}
                close={closeDelete}
                isMobile={isMobile}
            />
        </Box>
    );
}

ApprovalsPendingPayment.propTypes = {
    isMobile: PropTypes.bool,
    setSnackbarProperties: PropTypes.func,
};
