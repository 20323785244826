import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import SubscriptionApiService from '../../api/SubscriptionApiService';
import {Box, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import StandardUserEditButton from './OptionsButton/StandardUserEditButton';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import StorageUnitCardMobile from '../Mobile/StorageUnitCardMobile';
import ChangePaymentModal from '../Modal/StandardFunctions/ChangePaymentModal';
import authenticationManager from '../../Auth/AuthenticationManager';
import Backdrop from '@mui/material/Backdrop';
import * as Constants from '../../utilities/Constants';
import {useNavigate} from 'react-router-dom';
import {Label} from 'reactstrap';

export default function UsersStorageUnits(props) {
    const { setSnackbarProperties, isMobile } = props;
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCancelSubscription, setShowCancelSubscription] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState({});
    const [showEditPayment, setShowEditPayment] = useState(false);

    const subscriptionApiService = new SubscriptionApiService();

    const navigate = useNavigate();

    const columns = [
        {
            field: 'storageUnit.name',
            headerName: 'Storage Unit',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnit.name;
            }
        },
        {
            field: 'storageSize',
            headerName: 'Storage Size',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnit.storageSize;
            }
        },
        {
            field: 'storageUnitType',
            headerName: 'Storage Type',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnit.storageUnitType.name;
            }
        },
        {
            field: 'climateControlled',
            headerName: 'Climate Controlled',
            flex: 1,
            renderCell: (params) => {
                return params.row.storageUnit.climateControlled ? 'Yes' : 'No';
            }
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => {
                return Constants.USDollar.format(params.row.storageUnit.price);
            }
        },
        {
            field: 'nextReadyDate',
            headerName: 'End Date',
            flex: 1,
            renderCell: (params) => {
                const readyDate = params.row.storageUnit.nextReadyDate;
                return readyDate ? readyDate.toString().substring(0, readyDate.toString().length - 9) : 'N/A';
            }
        },
        {
            field: 'options',
            headerName: 'Options',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <StandardUserEditButton
                    params={params}
                    setShowDeleteDialog={setShowCancelSubscription}
                    setCurrentSelectedObject={setSelectedSubscription}
                    setShowEditDialog={setShowEditPayment}
                    editButtonText={'Edit Payment Method'}
                    deleteButtonText={params.row.storageUnit.nextReadyDate ? 'Already Cancelled' : 'Cancel Subscription'}
                    showEditButton={true}
                    showDeleteButton={true}
                    disableDeleteButton={params.row.storageUnit.nextReadyDate}
                />;
            },
        },
    ];

    async function handleConfirmCancelSubscription() {
        const response = await subscriptionApiService.cancelSubscription(selectedSubscription);

        if(response.success) {
            setSnackbarProperties('Cancelled Subscription');
            await getUserStorageUnits();
        } else {
            setSnackbarProperties(`Error cancelling subscription: ${response.message}`, 'error');
        }

        closeCancelConfirm();
    }

    function closeCancelConfirm() {
        setShowCancelSubscription(false);
        setSelectedSubscription(undefined);
    }

    async function getUserStorageUnits() {
        setLoading(true);

        const storageUnitsResponse = await subscriptionApiService.getSubscriptionsForUser();

        if(storageUnitsResponse.success) {
            setSubscriptions(storageUnitsResponse.data);
        } else {
            setSnackbarProperties(`Unable to retrieve storage units: ${storageUnitsResponse.message}`, 'error');
        }
        setLoading(false);
    }

    function hideCancelModal() {
        setShowCancelSubscription(false);
    }

    function showEditPaymentModal(subscription) {
        setSelectedSubscription(subscription);
        setShowEditPayment(true);
    }

    function showCancelSubscriptionModal(subscription) {
        setSelectedSubscription(subscription);
        setShowCancelSubscription(true);
    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized) {
            navigate('/login');
        }
        document.title = 'Executive Storage Suites - Your Storage Units';
        getUserStorageUnits();
    }, [showEditPayment]);

    return(
        <Box>
            <h1>Your Storage Units</h1>
            { isMobile ? loading ? <Backdrop open={loading}><CircularProgress color={'inherit'} /></Backdrop> :
                subscriptions.length > 0 ? subscriptions.map((sub, index) => {
                    return <StorageUnitCardMobile
                        key={index}
                        storageUnit={sub.storageUnit}
                        isOwned={true}
                        setOpenEditPayment={() => showEditPaymentModal(sub)}
                        setOpenCancelUnit={() => showCancelSubscriptionModal(sub)}
                    />;
                }) : <Label>You have no subscriptions to storage units</Label>
                :
                <Box sx={{height: '80vh'}}>
                    <DataGrid
                        rows={subscriptions}
                        columns={columns}
                        loading={loading}
                        getRowHeight={() => 'auto'}
                        getRowId={(row) => row.id}
                    />
                </Box>

            }

            <ChangePaymentModal
                open={showEditPayment}
                close={() => setShowEditPayment(false)}
                subscription={selectedSubscription}
                userId={authenticationManager.getUserId()}
                isMobile={isMobile}
                setSnackbarProperties={setSnackbarProperties}
            />
            <ConfirmDeleteModal
                open={showCancelSubscription}
                title={'Cancel Storage Unit'}
                confirmText={'Are you sure you wish to cancel your subscription?'}
                handleConfirm={handleConfirmCancelSubscription}
                close={hideCancelModal}
                isMobile={isMobile}
            />
        </Box>
    );
}

UsersStorageUnits.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
