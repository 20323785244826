import React, {useEffect} from 'react';

export default function CollegeStorage() {
    const collegeBanner = require('../../../resources/css/images/college-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - College Storage';
    }, []);
    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">
                        <article>
                            <header>
                                <h2>College Storage</h2>

                                <p>Located in East Troy, WI</p>
                            </header>
                            <span className="image featured"><img alt="" src={String(collegeBanner)} /></span>

                            <p>Just twenty miles from some of the major universities in the Milwaukee area, Executive Storage Suites offers convenient and quality East Troy, WI storage that is ideal for all college storage needs. Located along I-43, our East Troy storage property is just a short drive from Downtown Milwaukee and close to multiple college campuses.</p>

                            <h3>Southeast Wisconsin College Storage</h3>

                            <p>With numerous locations in and around Walworth County, the University of Wisconsin has campuses at Milwaukee, Whitewater, and Parkside in Kenosha. Additional local college campuses near East Troy include Marquette University in Milwaukee, Carroll University in Waukesha, and the Milwaukee Area Technical College.</p>

                            <h3>Storage for All Needs</h3>

                            <p>For college storage that offers flexible monthly leasing plans and affordable prices, local students need look no further than Executive Storage Suites in East Troy. Our brand new facility has climate controlled units in a wide range of different sizes to accommodate all belongings and budgets. Whether you need to store furniture, recreational gear or books, papers, and files, we have the solutions you need for clean and quality college storage.</p>

                            <h3>The Right Solution</h3>

                            <p>Finding a place to live during college can be a hassle, but choosing the right storage facility doesn’t have to be. Our property provides convenient and easy 24/7 access, so you can get to your belongings when you need them. Security is essential in keeping your belongings safe, and you can count on our coded gate entry, recorded video surveillance and motion detectors to protect your storage items.</p>

                            <p>Store your futon and lava lamp here until next semester, or use our easily accessible units as a go-to for all extra items you can’t quite fit inside your dorm room. Whatever you need to store, we’ve got a space for you here at Executive Storage Suites.</p>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    );
}
