import React, {useEffect} from 'react';

export default function About() {
    const collegeBanner = require('../../../resources/css/images/college-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - About';
    }, []);
    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">
                        <article>
                            <header>
                                <h2>About Us</h2>
                            </header>
                            <span className="image featured"><img alt="" src={String(collegeBanner)} /></span>

                            <p>Located twenty miles southwest of Milwaukee, East Troy is a small town of 5,000 with historic ties to the railroad and a lively outdoor recreation scene. For local businesses and residents in need of quality and affordable East Troy, WI storage, Executive Storage Suites is proud to serve all of Walworth County. We are right off of I-43, and easy to get to from the surrounding communities of Mukwonago, Lake Geneva, Burlington and Elkhorn.</p>

                            <h3>East Troy, WI</h3>

                            <p>East Troy’s Historic Village Square hosts a variety of fun and family-friendly seasonal events throughout the year, with a fine selection of local businesses and restaurants nearby. Visitors and locals alike enjoy visiting the East Troy Electric Railroad Museum and the seemingly endless chain of lakes surrounding the town.</p>

                            <h3>Outdoor Recreation</h3>

                            <p>With Lake Beulah, Booth Lake and Lake Geneva all right down the road, East Troy is a favorable destination for fishing, boating, water-skiing and enjoying the great outdoors. Located just south of town, the Alpine Valley Ski Hill, Resort and Music Theatre is another popular spot for winter sports and concerts throughout the year.</p>

                            <h3>Quality Storage in East Troy, WI</h3>

                            <p>Opened in 2016, our brand new storage facility at 2481 Executive Drive offers clean and secure storage rentals at affordable prices for the entire 53120 zip code area. All units have 24/7 access, secure coded gate entry, month to month leasing, and climate control. Taking care of our customers is top priority, and we are always happy to assist tenants in finding the perfect solution to meet all their self storage needs.</p>

                            <p><a href="contact">Contact Us</a> to learn more about our East Troy self storage, and come store with the best!</p>

                            <h3>Check us out on the East Troy Area Chamber of Commerce website by clicking <a href="https://easttroy.org/news/2016/dec/6/indoor-storage-units-open-business-park/" target="_blank" rel="noreferrer">here</a>.</h3>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    );
}
