import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {Button, Label} from 'reactstrap';
import StorageUnitDTO from '../../DTOs/StorageUnit/StorageUnitDTO';
import {USDollar} from '../../utilities/Constants';

export default function StorageUnitMaintenanceCardMobile(props) {
    const { key, storageUnit, showEditModal, showDeleteModal } = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`Storage Unit: ${storageUnit.name}`}</Label>
            <Label>{`Current User: ${storageUnit.currentUser?.firstName ?? ''} ${storageUnit.currentUser?.lastName ?? ''} (${storageUnit.currentUser?.username ?? 'N/A'})`}</Label>
            <Label>{`Section: ${storageUnit.section.name}`}</Label>
            <Label>{`Facility: ${storageUnit.facility.name}`}</Label>
            <Label>{`Storage Size: ${storageUnit.storageSize}`}</Label>
            <Label>{`Storage Unit Type: ${storageUnit.storageUnitType.name}`}</Label>
            <Label>{`Climate Controlled: ${storageUnit.climateControlled ? 'Yes' : 'No'}`}</Label>
            <Label>{`Price: ${USDollar.format(storageUnit.price)}`}</Label>
            <Label>{`Cancellation Date: ${storageUnit.nextReadyDate?.toString().substring(0, storageUnit.nextReadyDate.toString().length - 9) ?? 'N/A'}`}</Label>
            <Label>{`Hold? ${storageUnit.hold ? 'Yes' : 'No'}`}</Label>
            <Button className={'mobile-button'} onClick={showEditModal}>Edit Storage Unit</Button>
            <Button className={'mobile-button'} color={'danger'} onClick={showDeleteModal}>Delete Storage Unit</Button>
        </Box>
    );
}

StorageUnitMaintenanceCardMobile.propTypes = {
    key: PropTypes.number,
    storageUnit: PropTypes.objectOf(StorageUnitDTO).isRequired,
    showEditModal: PropTypes.func.isRequired,
    showDeleteModal: PropTypes.func.isRequired,
};
