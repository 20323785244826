import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormLabel, TextField} from '@mui/material';
import {Button} from 'reactstrap';

export default function PasswordResetModal(props) {
    const {open, close, loading, handleSubmitReset} = props;

    const[email, setEmail] = useState('');
    return(
        <Dialog open={open}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <FormLabel>Please enter your E-mail Address</FormLabel>
                <TextField value={email} onChange={(event) => setEmail(event.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} className={'mobile-modal-button'} variant={'contained'} onClick={close}>Cancel</Button>
                <Button disabled={loading} className={'mobile-modal-button'} variant={'contained'} onClick={() => handleSubmitReset(email)}>{loading ? 'Loading' : 'Submit'}</Button>
            </DialogActions>
        </Dialog>
    );
}

PasswordResetModal.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
    handleSubmitReset: PropTypes.func.isRequired,
};
