import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import {Box, Button, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import UserApiService from '../../../api/UserApiService';
import {AppUserDTO} from '../../../DTOs/User/AppUserDTO';
import Grid from '@mui/material/Grid';
import {FormGroup} from 'reactstrap';
import AppUserGenericDTO from '../../../DTOs/User/AppUserGenericDTO';

export default function EditUserModal(props) {
    const { onClose, open, userInfo, setSnackbarProperties, userApiService, onCancel } = props;

    const[id, setId] = useState(0);
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [driversLicense, setDriversLicense] = useState('');
    const [licenseState, setLicenseState] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');

    const [firstnameError, setFirstnameError] = useState(false);
    const [lastnameError, setLastnameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [driversLicenseError, setDriversLicenseError] = useState(false);
    const [licenseStateError, setLicenseStateError] = useState(false);

    function validateInfo() {
        let errorMessage = '';

        if(!firstname) {
            errorMessage += errorMessage === '' ? 'First Name is required' : ', First Name is required';
        }

        setFirstnameError(!firstname);

        if(!lastname) {
            errorMessage += errorMessage === '' ? 'Last Name is required' : ', Last Name is required';
        }

        setLastnameError(!lastname);

        if(!phone) {
            errorMessage += errorMessage === '' ? 'Phone # is required' : ', Phone # is required';
        }

        setPhoneError(!phone);

        if(!driversLicense) {
            errorMessage += errorMessage === '' ? 'Driver\'s License is required' : ', Driver\'s License is required';
        }

        setDriversLicenseError(!driversLicense);

        if(!licenseState) {
            errorMessage += errorMessage === '' ? 'License State is required' : ', License State is required';
        }

        setLicenseStateError(!licenseState);

        return errorMessage;

    }


    async function handleSave() {
        const errors = validateInfo();

        if(!errors) {
            const newUser = new AppUserGenericDTO();
            newUser.id = id;
            newUser.username = email;
            newUser.firstName = firstname;
            newUser.lastName = lastname;
            newUser.phone = phone;
            newUser.driversLicenseNumber = driversLicense;
            newUser.licenseState = licenseState;
            newUser.address1 = address1;
            newUser.address2 = address2;
            newUser.city = city;
            newUser.state = state;
            newUser.zip = zipcode;

            const response = await userApiService.updateUserInfo(newUser);

            if(response.success) {
                onClose();
            } else {
                setSnackbarProperties(`Unable to update information: ${response.message}`, 'error');
            }
        }
    }

    function handleCancel() {
        onCancel();
    }

    useEffect(() => {
        if(open) {
            setId(userInfo?.id ?? 0);
            setEmail(userInfo?.username ?? '');
            setFirstname(userInfo?.firstName ?? '');
            setLastname(userInfo?.lastName ?? '');
            setPhone(userInfo?.phone ?? '');
            setDriversLicense(userInfo?.driversLicenseNumber ?? '');
            setLicenseState(userInfo?.licenseState ?? '');
            setAddress1(userInfo?.address1 ?? '');
            setAddress2(userInfo?.address2 ?? '');
            setCity(userInfo?.city ?? '');
            setState(userInfo?.state ?? '');
            setZipcode(userInfo?.zip ?? '');
        }
    }, [userInfo, open]);

    return(
        <Dialog
            closeAfterTransition={true}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth="sm"
            fullWidth
            open={open}
        >
            <DialogTitle>{userInfo ? 'Edit ' + userInfo.username : 'Add New User'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <FormGroup>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={{width: '100%'}}
                                    error={firstnameError}
                                    type='text'
                                    id='firstName'
                                    label='First Name'
                                    value={firstname} onChange={(event) => setFirstname(event.target.value)}
                                />
                            </Grid>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={{width: '100%'}}
                                    error={lastnameError}
                                    type='text'
                                    id='lastName'
                                    label="Last Name"
                                    value={lastname}
                                    onChange={(event) => setLastname(event.target.value)}
                                />
                            </Grid>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={{width: '100%'}}
                                    error={phoneError}
                                    type='text'
                                    id='phone'
                                    label="Phone"
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                />
                            </Grid>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={{width: '100%'}}
                                    type='text'
                                    error={driversLicenseError}
                                    id='driverLicense'
                                    label="Driver's License" value={driversLicense}
                                    onChange={(event) => setDriversLicense(event.target.value)}
                                />
                            </Grid>
                            <Grid item className='col-12'>
                                <TextField
                                    sx={{width: '100%'}}
                                    error={licenseStateError}
                                    type='text'
                                    id='licenseState'
                                    label="License State"
                                    value={licenseState}
                                    onChange={(event) => setLicenseState(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={'Address Line 1'}
                                    value={address1}
                                    onChange={(event) => setAddress1(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={'Address Line 2'}
                                    value={address2}
                                    onChange={(event) => setAddress2(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={'City'}
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={'State'}
                                    value={state}
                                    onChange={(event) => setState(event.target.value)}
                                />
                            </Grid>
                            <Grid item className={'col-12'}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={'Zip Code'}
                                    value={zipcode}
                                    onChange={(event) => setZipcode(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='contained' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

EditUserModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    userInfo: PropTypes.objectOf(AppUserDTO),
    setSnackbarProperties: PropTypes.func,
    userApiService: PropTypes.objectOf(UserApiService).isRequired,
    onCancel: PropTypes.func.isRequired,
};
