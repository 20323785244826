import PropTypes from 'prop-types';
import StorageUnitDTO from '../../DTOs/StorageUnit/StorageUnitDTO';
import {Box} from '@mui/material';
import {Button, Label} from 'reactstrap';
import {USDollar} from '../../utilities/Constants';
import React from 'react';

export default function HoldStorageUnitCardMobile(props) {
    const {key, storageUnit, showHoldModal, showAssignModal} = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`Storage Unit: ${storageUnit.name}`}</Label>
            <Label>{`Section: ${storageUnit.section.name}`}</Label>
            <Label>{`Facility: ${storageUnit.facility.name}`}</Label>
            <Label>{`Storage Size: ${storageUnit.storageSize}`}</Label>
            <Label>{`Storage Unit Type: ${storageUnit.storageUnitType.name}`}</Label>
            <Label>{`Climate Controlled: ${storageUnit.climateControlled ? 'Yes' : 'No'}`}</Label>
            <Label>{`Price: ${USDollar.format(storageUnit.price)}`}</Label>
            <Label>{`Hold? ${storageUnit.hold ? 'Yes' : 'No'}`}</Label>
            <Button className={'mobile-button'} onClick={showHoldModal}>{storageUnit.hold ? 'Make Unit Available' : 'Hold Storage Unit'}</Button>
            <Button className={'mobile-button'} color={'danger'} onClick={showAssignModal}>Manually Assign Storage Unit</Button>
        </Box>
    );
}

HoldStorageUnitCardMobile.propTypes = {
    key: PropTypes.number,
    storageUnit: PropTypes.objectOf(StorageUnitDTO),
    showHoldModal: PropTypes.func.isRequired,
    showAssignModal: PropTypes.func.isRequired,
};
