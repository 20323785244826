import apiService from './ApiService';

export default class StorageUnitManagementApiService {

    /**
     * Get a list of all storage units
     * @returns {Promise<ServiceResponse<Array<StorageUnitDTO>>>}
     */
    async getAllStorageUnits() {
        const storageUnits = await apiService.getData('api/admin/StorageUnitMaintenance');
        return storageUnits.data;
    }

    /**
     * Get specified storage unit
     * @param id {int}
     * @return {Promise<ServiceResponse<StorageUnitDTO>>}
     */
    async getStorageUnit(id) {
        const storageUnit = await apiService.getData('api/admin/StorageUnitMaintenance/' + id);
        return storageUnit.data;
    }

    /**
     * Updates a storage unit
     * @param storageUnitData {StorageUnitMaintenanceDTO}
     * @return {Promise<ServiceResponse<StorageUnitDTO>>}
     */
    async updateStorageUnit(storageUnitData) {
        const storageUnit = await apiService.putData('api/admin/StorageUnitMaintenance', storageUnitData);
        return storageUnit.data;
    }

    /**
     * Add a new storage unit
     * @param storageUnitData {StorageUnitMaintenanceDTO}
     * @return {Promise<ServiceResponse<StorageUnitMaintenanceDTO>>}
     */
    async addStorageUnit(storageUnitData) {
        const storageUnit = await apiService.postData('api/admin/StorageUnitMaintenance', storageUnitData);
        return storageUnit.data;
    }

    /**
     * Delete a storage unit
     * @param storageUnitId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deleteStorageUnit(storageUnitId) {
        const storageUnit = await apiService.deleteData(`/api/admin/StorageUnitMaintenance/${storageUnitId}`, null);

        return storageUnit.data;
    }

    /**
     * Get Storage Unit Types
     * @return {Promise<ServiceResponse<Array<StorageUnitTypeDTO>>>}
     */
    async getStorageUnitTypes() {
        const storageUnitTypes = await apiService.getData('/api/admin/StorageUnitMaintenance/types');

        return storageUnitTypes.data;
    }

    /**
     * Hold a storage unit
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async holdStorageUnit(storageUnitId) {
        const hold = await apiService.postData(`api/admin/StorageUnitMaintenance/hold/${storageUnitId}`, null);

        return hold.data;
    }

    /**
     * Manually Assign a storage unit
     * @param storageUnitId {number}
     * @param userId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async manuallyAssignStorageUnit(storageUnitId, userId) {
        const assigned = await apiService.postData(`api/admin/StorageUnitMaintenance/assign/unit/${storageUnitId}/user/${userId}`, null);

        return assigned.data;
    }

    async getAllHoldableUnits() {
        const units = await apiService.getData('api/admin/StorageUnitMaintenance/holdable');

        return units.data;
    }
}
