import React from 'react';
import PropTypes from 'prop-types';
import PaymentLogDTO from '../../DTOs/Subscription/PaymentLogDTO';
import {Box} from '@mui/material';
import {Label} from 'reactstrap';
import {USDollar} from '../../utilities/Constants';

export default function TransactionCardMobile(props) {
    const {key, transaction} = props;

    return(
        <Box className={'storage-unit-card-mobile'} key={key} border={1} borderColor={'darkgray'}>
            <Label>{`Transaction ID: ${transaction.transactionid}`}</Label>
            <Label>{`Transaction Date: ${transaction.transactionDate.toString().substring(0, transaction.transactionDate.toString().length - 9)}`}</Label>
            <Label>{`User: ${transaction.appUser.firstName} ${transaction.appUser.lastName} (${transaction.appUser.username})`}</Label>
            <Label>{`Storage Unit: ${transaction.storageUnit.name}`}</Label>
            <Label>{`Authorize.net Status: ${transaction.paymentProcessorStatus}`}</Label>
            <Label>{`Amount: ${USDollar.format(transaction.amount)}`}</Label>
        </Box>
    );
}

TransactionCardMobile.propTypes = {
    key: PropTypes.number,
    transaction: PropTypes.objectOf(PaymentLogDTO),
};
