import React, {useEffect} from 'react';

export default function ContactUs() {
    const contactBanner = require('../../resources/css/images/contact-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - Contact Us';
    }, []);
    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div className="row gtr-200">
                        <div className="col-8 col-12-narrower">
                            <div id="content">
                                <article>
                                    <header>
                                        <h2>Contact Executive Storage Suites in East Troy, WI</h2>
                                    </header>
                                    <span className="image featured"><img alt="" src={String(contactBanner)} /></span>

                                    <p>Conveniently located less than one mile south of I-43, Executive Storage Suites offers quality and affordable East Troy, WI storage that is easy to get to from all local and surrounding areas. Our brand new storage center is part of the East Troy Business Center, one half mile east of State Rd 120.</p>

                                    <h3>24-Hour Storage Center</h3>

                                    <p>Whether you are coming from Downtown, Mukwonago, Lake Geneva, Burlington or Elkhorn, you will find all you need for clean and secure storage in Walworth County at Executive Storage Suites. We offer 24-hour storage in a wide variety of different sizes with convenient monthly rental options to accommodate all belongings and budgets. Call, email or stop by to start storing with us today!</p>
                                </article>
                            </div>
                        </div>

                        <div className="col-4 col-12-narrower">
                            <div id="sidebar">
                                <section>
                                    <h3>Contact Information</h3>

                                    <p><b>Executive Storage Suites</b><br/>
                                        2481 Executive Drive<br/>
                                        East Troy, WI 53189</p>

                                    <p><b>Phone:</b> (414) 659-3045<br/>
                                        <b>Fax:</b> (262) 642-5395</p>

                                    <p><b>Email:</b> <a href="mailto:Sandynyff@yahoo.com">Sandynyff@yahoo.com</a></p>

                                    <p><b>Gate Access</b><br/>
                                        24/7 Access</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
