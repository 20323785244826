import apiService from './ApiService';

export default class FacilityApiService {

    /**
     * Gets all facilities
     * @return {Promise<ServiceResponse<Array<FacilityDTO>>>}
     */
    async getFacilities() {
        const facilities = await apiService.getData('api/admin/facility');

        return facilities.data;
    }
}
