import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import * as Constants from '../../utilities/Constants';
import StandardUserEditButton from '../StandardUserFunctions/OptionsButton/StandardUserEditButton';
import StorageUnitManagementApiService from '../../api/StorageUnitManagementApiService';
import PropTypes from 'prop-types';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import ManualAssignStorageUnit from '../Modal/AdminFunctions/ManualAssignStorageUnit';
import UserApiService from '../../api/UserApiService';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import HoldStorageUnitCardMobile from '../Mobile/HoldStorageUnitCardMobile';

export default function StorageUnitHolds(props) {
    const {setSnackbarProperties, isTablet, isMobile} = props;

    const[storageUnits, setStorageUnits] = useState([]);
    const[showHoldUnit, setShowHoldUnit] = useState(false);
    const[showManualAssignUnit, setShowManualAssignUnit] = useState(false);
    const[selectedUnit, setSelectedUnit] = useState();
    const[users, setUsers] = useState([]);
    const[selectedUser, setSelectedUser] = useState();
    const[pageUpdate, setPageUpdate] = useState(0);

    const storageUnitManagementApiService = new StorageUnitManagementApiService();
    const userApiService = new UserApiService();

    const navigate = useNavigate();

    const columnsDesktop= [
        {
            field: 'name',
            headerName: 'Storage Unit',
            flex: 1,
        },
        {
            field: 'storageSize',
            headerName: 'Storage Size',
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => {
                return Constants.USDollar.format(params.row.price);
            }
        },
        {
            field: 'hold',
            headerName: 'Hold?',
            flex: 0.5,
            renderCell: (params) => {
                return params.row.hold ? 'Yes' : 'No';
            }
        },
        {
            field: '',
            headerName: '',
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowManualAssignUnit}
                    setShowEditDialog={setShowHoldUnit}
                    setCurrentSelectedObject={setSelectedUnit}
                    params={params}
                    editButtonText={params.row.hold ? 'Make Unit Available' : 'Hold Storage Unit'}
                    deleteButtonText={'Manually Assign Unit'}
                />;
            }
        }
    ];

    const columnsTablet= [
        {
            field: 'name',
            headerName: 'Storage Unit',
            flex: 1,
        },
        {
            field: 'storageSize',
            headerName: 'Storage Size',
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => {
                return Constants.USDollar.format(params.row.price);
            }
        },
        {
            field: 'hold',
            headerName: 'Hold?',
            flex: 0.5,
            renderCell: (params) => {
                return params.row.hold ? 'Yes' : 'No';
            }
        },
        {
            headerName: '',
            flex: 0.75,
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowManualAssignUnit}
                    setShowEditDialog={setShowHoldUnit}
                    setCurrentSelectedObject={setSelectedUnit}
                    params={params}
                    editButtonText={params.row.hold ? 'Make Unit Available' : 'Hold Storage Unit'}
                    deleteButtonText={'Manually Assign Unit'}
                />;
            }
        }
    ];

    function closeHoldDialog() {
        setShowHoldUnit(false);
        setSelectedUnit(undefined);
    }

    function closeManualAssignDialog() {
        setShowManualAssignUnit(false);
        setSelectedUser(undefined);
    }

    async function handleConfirmHoldUnit() {
        const hold = await storageUnitManagementApiService.holdStorageUnit(selectedUnit.id);

        if(hold.success) {
            setSnackbarProperties(selectedUnit.hold ? 'Unit is now available' : 'Successfully Holding Unit');
        } else {
            setSnackbarProperties(`Error Holding Unit: ${hold.message}`, 'error');
        }

        closeHoldDialog();
        setPageUpdate(pageUpdate === 100 ? 0 : pageUpdate + 1);
    }

    async function handleManualAssign() {
        const manualAssign = await storageUnitManagementApiService.manuallyAssignStorageUnit(selectedUnit.id, selectedUser.id);

        if(manualAssign.success) {
            setSnackbarProperties('Successfully Assigned Unit');
        } else {
            setSnackbarProperties(`Error Assigning Unit: ${manualAssign.message}`, 'error');
        }

        closeManualAssignDialog();
        setPageUpdate(pageUpdate === 100 ? 0 : pageUpdate + 1);
    }

    async function getStorageUnits() {
        const storageUnitsResponse = await storageUnitManagementApiService.getAllHoldableUnits();

        if(storageUnitsResponse.success) {
            setStorageUnits(storageUnitsResponse.data);
        } else {
            setSnackbarProperties(`Error getting storage unit data: ${storageUnitsResponse.message}`, 'error');
        }
    }

    async function getUsers() {
        const usersResponse = await userApiService.getAllUsersGeneric();

        if(usersResponse.success) {
            setUsers(usersResponse.data);
        } else {
            setSnackbarProperties(`Error getting user data: ${usersResponse.message}`, 'error');
        }
    }


    useEffect(() => {
        if(!authenticationManager.isAuthorized || (!authenticationManager.isAdmin() && !authenticationManager.isSuperAdmin())) {
            navigate('/login');
        }

        document.title = 'Executive Storage Suites - Hold Storage Units';
        getStorageUnits();
        getUsers();
    }, [pageUpdate]);

    return(
        <Box>
            {isMobile ?
                storageUnits.map((storageUnit, index) => {
                    return <HoldStorageUnitCardMobile
                        key={index}
                        storageUnit={storageUnit}
                        showHoldModal={() => {setSelectedUnit(storageUnit); setShowHoldUnit(true);}}
                        showAssignModal={() => {setSelectedUnit(storageUnit); setShowManualAssignUnit(true);}}
                    />;
                })
                :
                <Box sx={{height: '80vh'}}>
                    <h1>Hold a Storage Unit</h1>
                    <DataGridPro
                        columns={isTablet ? columnsTablet : columnsDesktop}
                        rows={storageUnits}
                    />

                </Box>
            }
            <ConfirmDeleteModal
                open={showHoldUnit}
                title={'Hold Storage Unit'}
                confirmText={`Are you sure you want to hold the storage unit '${selectedUnit?.name}'`}
                handleConfirm={handleConfirmHoldUnit}
                close={closeHoldDialog}
                isMobile={isMobile}
            />
            <ManualAssignStorageUnit
                open={showManualAssignUnit}
                storageUnit={selectedUnit}
                users={users}
                isMobile={isMobile}
                handleAssign={handleManualAssign}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                close={closeManualAssignDialog}
            />
        </Box>

    );
}

StorageUnitHolds.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isTablet: PropTypes.bool,
    isMobile: PropTypes.bool,
};
