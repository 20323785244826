import apiService from './ApiService';

export default class ContactFormApiService {

    /**
     * Send a contact request
     * @param contactRequest {ContactFormDTO}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async sendContactForm(contactRequest) {
        const response = await apiService.postData('api/contactForm', contactRequest);

        return response.data;
    }
}
