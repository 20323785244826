import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SubscriptionApiService from '../../api/SubscriptionApiService';
import {Box} from '@mui/material';
import StandardUserEditButton from '../StandardUserFunctions/OptionsButton/StandardUserEditButton';
import ConfirmDeleteModal from '../Modal/StandardFunctions/ConfirmDeleteModal';
import {DataGridPro} from '@mui/x-data-grid-pro';
import PendingApprovalsUserDetailPanel from './DetailPanels/PendingApprovalsUserDetailPanel';
import {useNavigate} from 'react-router-dom';
import authenticationManager from '../../Auth/AuthenticationManager';
import PendingApprovalsCardMobile from '../Mobile/PendingApprovalsCardMobile';
import {Label} from 'reactstrap';

export default function PendingApprovals(props) {
    const { setSnackbarProperties, isMobile } = props;

    const[pendingApprovals, setPendingApprovals] = useState([]);
    const[selectedPendingApproval, setSelectedPendingApproval] = useState();
    const[showDenyDialog, setShowDenyDialog] = useState(false);
    const[showApproveDialog, setShowApproveDialog] = useState(false);
    const[loading, setLoading] = useState(false);
    const[updatePage, setUpdatePage] = useState(0);

    const subscriptionApiService = new SubscriptionApiService();

    const navigate = useNavigate();

    async function handleDeny() {
        const response = await subscriptionApiService.denyPendingApproval(selectedPendingApproval.id);

        if(response.success) {
            setSnackbarProperties('Denied Request', 'warning');
        } else {
            setSnackbarProperties(`Error Denying Request: ${response.message}`, 'error');
        }

        closeDeny();
        setUpdatePage(updatePage == 100 ? 0 : updatePage + 1);
    }

    function closeDeny() {
        setSelectedPendingApproval(undefined);
        setShowDenyDialog(false);
    }

    async function handleApprove() {
        const response = await subscriptionApiService.approvePendingApproval(selectedPendingApproval.id);

        if(response.success) {
            setSnackbarProperties('Approved Request');
        } else {
            setSnackbarProperties(`Error Approving Request: ${response.message}`, 'error');
        }
        closeApprove();
        setUpdatePage(updatePage == 100 ? 0 : updatePage + 1);
    }

    function closeApprove() {
        setSelectedPendingApproval(undefined);
        setShowApproveDialog(false);
    }

    const columns = [
        {
            field: 'appUser.username',
            headerName: 'User',
            flex: 1,
            renderCell: (params) => {
                return `${params.row.appUser.username}`;
            }
        },
        {
            field: 'appUser.firstName',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => {
                return `${params.row.appUser.firstName} ${params.row.appUser.lastName}`;
            }
        },
        {
            field: 'storageUnit.name',
            headerName: 'Storage Unit',
            flex: 1,
            renderCell: (params) => {
                return `${params.row.storageUnit.name}`;
            }
        },
        {
            field: '',
            headerName: 'Options',
            flex: 0.5,
            renderCell: (params) => {
                return <StandardUserEditButton
                    setShowDeleteDialog={setShowDenyDialog}
                    setShowEditDialog={setShowApproveDialog}
                    setCurrentSelectedObject={setSelectedPendingApproval}
                    params={params}
                    editButtonText={'Approve'}
                    deleteButtonText={'Deny'}
                />;
            }
        }
    ];

    async function getPendingApprovals() {
        setLoading(true);
        const pendingApprovals = await subscriptionApiService.getPendingApprovals();

        if(pendingApprovals.success) {
            setPendingApprovals(pendingApprovals.data);
        } else {
            setSnackbarProperties('Error retrieving Pending Approvals: ' + pendingApprovals.message, 'error');
        }
        setLoading(false);
    }

    const getDetailPanelContent = React.useCallback(({row}) => <PendingApprovalsUserDetailPanel row={row} />, [], );

    const getDetailPanelHeight = React.useCallback(() => 200, []);

    useEffect(() => {
        if(!authenticationManager.isAuthorized || (!authenticationManager.isAdmin() && !authenticationManager.isSuperAdmin())) {
            navigate('/login');
        }

        document.title = 'Executive Storage Suites - Pending Approvals';
        getPendingApprovals();
    }, [updatePage]);

    return(
        <Box>
            <h1>Pending Approvals</h1>
            {isMobile ? pendingApprovals.length > 0 ?
                pendingApprovals.map((pendingApproval, key) => {
                    return <PendingApprovalsCardMobile
                        key={key}
                        pendingApproval={pendingApproval}
                        setShowApproveDialog={() => {
                            setSelectedPendingApproval(pendingApproval);
                            setShowApproveDialog(true);
                        }}
                        setShowDenyDialog={() => {
                            setSelectedPendingApproval(pendingApproval);
                            setShowDenyDialog(true);
                        }}
                    />;
                }) : <Label>There are no pending approvals</Label> :
                <Box sx={{height: '80vh'}}>
                    <DataGridPro
                        columns={columns}
                        rows={pendingApprovals}
                        loading={loading}
                        getRowHeight={() => 'auto'}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                    />


                </Box>
            }
            <ConfirmDeleteModal
                open={showDenyDialog}
                title={'Deny Pending Request'}
                confirmText={'Are you sure you wish to deny this request?'}
                handleConfirm={handleDeny}
                close={closeDeny}
                isMobile={isMobile}
            />

            <ConfirmDeleteModal
                open={showApproveDialog}
                title={'Approve Pending Request'}
                confirmText={'Are you sure you wish to approve this request?'}
                handleConfirm={handleApprove}
                close={closeApprove}
                isMobile={isMobile}
            />
        </Box>
    );
}

PendingApprovals.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
