import dayjs from 'dayjs';
import {Box, TextField} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React, {useEffect, useState} from 'react';
import PaymentLogApiService from '../../api/PaymentLogApiService';
import PropTypes from 'prop-types';
import {USDollar} from '../../utilities/Constants';
import authenticationManager from '../../Auth/AuthenticationManager';
import {useNavigate} from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TransactionCardMobile from '../Mobile/TransactionCardMobile';


export default function RecentTransactions(props) {
    const { setSnackbarProperties, isMobile } = props;

    const navigate = useNavigate();

    const today = dayjs(new Date());
    const firstOfMonth = dayjs(new Date(today.year(), today.month(), 1));

    const[transactions, setTransactions] = useState([]);
    const[fromDate, setFromDate] = useState(firstOfMonth);
    const[toDate, setToDate] = useState(today);
    const[loading ,setLoading] = useState(false);
    const[search, setSearch] = useState('');
    const[searchTransactions, setSearchTransactions] = useState([]);

    const paymentLogApiService = new PaymentLogApiService();

    const columns= [
        {
            field: 'transactionid',
            headerName: 'Transaction ID',
            flex: 1,
        },
        {
            field: 'transactionDate',
            headerName: 'Transaction Date',
            flex: 1,
            renderCell: (params) => {
                return params.row.transactionDate.toString().substring(0, params.row.transactionDate.toString().length - 9);
            },
            valueGetter: (params) => {
                return params.row.transactionDate.toString().substring(0, params.row.transactionDate.toString().length - 9);
            },
        },
        {
            field: 'appUser.firstName',
            headerName: 'User',
            flex: 2,
            renderCell: (params) => {
                return `${params.row.appUser.firstName} ${params.row.appUser.lastName} (${params.row.appUser.username})`;
            },
            valueGetter: (params) => {
                return `${params.row.appUser.firstName} ${params.row.appUser.lastName} (${params.row.appUser.username})`;
            }
        },
        {
            field: 'storageUnit.name',
            headerName: 'Storage Unit',
            flex: 1,
            renderCell: (params) => {
                return `${params.row.storageUnit.name}`;
            },
            valueGetter: (params) => {
                return `${params.row.storageUnit.name}`;
            }
        },
        {
            field: 'paymentProcessorStatus',
            headerName: 'Authorize.net Status',
            flex: 1,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            renderCell: (params) => {
                return USDollar.format(params.row.amount);
            },
            valueGetter: (params) => {
                return USDollar.format(params.row.amount);
            }
        },
    ];

    async function handleFromChange(newValue) {
        console.log(newValue);
        setFromDate(newValue);
        await getTransactions();
    }

    async function handleToChange(newValue) {
        console.log(newValue);
        setToDate(newValue);
        await getTransactions();
    }

    async function getTransactions() {
        setLoading(true);
        const response = await paymentLogApiService.getPaymentLogs(fromDate, toDate);

        if(response.success) {
            setTransactions(response.data);
            setSearchTransactions(response.data);
        } else if(response.message) {
            setSnackbarProperties(`${response.message}`, 'error');
        } else {
            setSnackbarProperties(`${response}`, 'error');
        }
        setLoading(false);
    }

    function handleSearchChange(value) {
        setSearch(value);

        if(value === '') {
            setSearchTransactions(transactions);
        } else {
            setSearchTransactions(transactions.filter((transaction) => transaction.transactionid.includes(value) || transaction.appUser.firstName.toUpperCase().includes(value.toUpperCase()) || transaction.appUser.lastName.toUpperCase().includes(value.toUpperCase()) || transaction.appUser.username.toUpperCase().includes(value.toUpperCase()) || transaction.storageUnit.name.toUpperCase().includes(value.toUpperCase()) || transaction.storageUnit.price.toString().includes(value) || transaction.paymentProcessorStatus.toUpperCase().includes(value.toUpperCase())));
        }
    }

    useEffect(() => {
        if(!authenticationManager.isAuthorized || (!authenticationManager.isAdmin() && !authenticationManager.isSuperAdmin())) {
            navigate('/login');
        }
        document.title = 'Executive Storage Suites - Recent Transactions';
        getTransactions();
    }, [fromDate, toDate]);

    return(
        <Box>
            <h1>Recent Transactions</h1>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{display: 'flex'}}>
                <DesktopDatePicker
                    label={'From Date'}
                    inputFormat={'MM/DD/YYYY'}
                    value={dayjs(fromDate)}
                    onChange={handleFromChange}
                    sx={isMobile ? {marginBottom: '15px', width: '100%'} : {marginRight: '15px'} }
                />
                <DesktopDatePicker
                    label={'To Date'}
                    inputFormat={'MM/DD/YYYY'}
                    value={dayjs(toDate)}
                    onChange={handleToChange}
                    sx={isMobile ? {width: '100%'} : {}}
                />
            </LocalizationProvider>
            <br/>
            <TextField sx={isMobile ? {marginTop: '15px', width: '100%'} : {marginTop: '15px'}} label={'Search'} value={search} onChange={(event) => handleSearchChange(event.target.value)}/>
            <br/><br/>
            {isMobile ?
                searchTransactions.map((transaction, index) => {
                    return <TransactionCardMobile key={index} transaction={transaction} />;
                })
                :
                <Box sx={{height: '70vh', marginBottom: '140px'}}>
                    <DataGridPro columns={columns} rows={transactions} loading={loading} sx={{marginBottom: '30px'}}/>
                    <br/><br/>
                </Box>
            }

        </Box>

    );
}

RecentTransactions.propTypes = {
    setSnackbarProperties: PropTypes.func,
    isMobile: PropTypes.bool,
};
