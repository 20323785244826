import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import PendingApprovalDTO from '../../../DTOs/Subscription/PendingApprovalDTO';
import * as Constants from '../../../utilities/Constants';

export default function PendingApprovalsUserDetailPanel(props) {
    const {row} = props;
    return (
        <Stack
            sx={{ py: 2, height: '100%', boxSizing: 'border-box'}}
            direction={'column'}
        >
            <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                <Stack direction={'column'} spacing={1} sx={{ height: 1 }}>
                    <Grid container>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>E-mail</Typography>
                            <Typography variant={'body1'}>{row.appUser.username}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Full Name</Typography>
                            <Typography variant={'body1'}>{`${row.appUser.firstName} ${row.appUser.lastName}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Phone Number</Typography>
                            <Typography variant={'body1'}>{`${row.appUser.phone}`}</Typography>
                        </Grid>
                        <Grid item md={4} sx={{marginTop: '20px'}} xs={4}>
                            <Typography variant={'body2'}>Driver&apos;s License Number</Typography>
                            <Typography variant={'body1'}>{`${row.appUser.driversLicenseNumber}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{marginTop: '20px'}}>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Storage Unit Name</Typography>
                            <Typography variant={'body1'}>{`${row.storageUnit.name}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Storage Unit Size</Typography>
                            <Typography variant={'body1'}>{`${row.storageUnit.storageSize}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Storage Unit Price</Typography>
                            <Typography variant={'body1'}>{`${Constants.USDollar.format(row.storageUnit.price)}`}</Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Paper>
        </Stack>
    );
}

PendingApprovalsUserDetailPanel.propTypes = {
    row: PropTypes.objectOf(PendingApprovalDTO),
};
