import React from 'react';
import Home from './components/Home';
import UserRegistration from './components/Authorization/UserRegistration';
import {Logout} from './components/Authorization/Logout';
import UserInfo from './components/StandardUserFunctions/UserInfo';
import UsersStorageUnits from './components/StandardUserFunctions/UsersStorageUnits';
import AvailableStorageUnits from './components/StandardUserFunctions/AvailableStorageUnits';
import PendingApprovals from './components/AdminFunctions/PendingApprovals';
import StorageUnitMaintenance from './components/AdminFunctions/StorageUnitMaintenance';
import About from './components/_static/MoreInfo/About';
import CollegeStorage from './components/_static/MoreInfo/CollegeStorage';
import Features from './components/_static/Features';
import SpaceCalculator from './components/_static/SpaceCalculator';
import ContactUs from './components/_static/ContactUs';
import StorageTips from './components/_static/StorageTips';
import TermsConditions from './components/_static/TermsConditions';
import PrivacyPolicy from './components/_static/PrivacyPolicy';
import Index from './components/_static/Index';
import CurrentSubscriptions from './components/AdminFunctions/CurrentSubscriptions';
import NewSubscriptionPaymentPage from './components/StandardUserFunctions/NewSubscriptionPaymentPage';
import StorageUnitHolds from './components/AdminFunctions/StorageUnitHolds';
import UsersAdmin from './components/AdminFunctions/UsersAdmin';
import RecentTransactions from './components/AdminFunctions/RecentTransactions';
import PasswordResetPage from './components/StandardUserFunctions/PasswordResetPage';
import ApprovalsPendingPayment from './components/AdminFunctions/ApprovalsPendingPayment';

const AppRoutes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: '/register',
        element: <UserRegistration />,
    },
    {
        path: '/logout',
        element: <Logout />,
    },
    {
        path:'/user-info',
        element: <UserInfo />,
        authRequired: true,
    },
    {
        path:'/user-storage-units',
        element: <UsersStorageUnits />,
        authRequired: true,
    },
    {
        path:'/available-units',
        element: <AvailableStorageUnits />,
        authRequired: true,
    },
    {
        path:'/admin/pending-approvals',
        element: <PendingApprovals />,
        authRequired: true,
    },
    {
        path:'/admin/storage-unit-maintenance',
        element: <StorageUnitMaintenance />,
        authRequired: true,
    },
    {
        path:'/admin/current-subscriptions',
        element: <CurrentSubscriptions />,
        authRequired: true,
    },
    {
        path:'/new-subscription-payment-details/:token',
        element: <NewSubscriptionPaymentPage />,
        authRequired: false,
    },
    {
        path:'/admin/hold-storage-units',
        element: <StorageUnitHolds />,
        authRequired: true,
    },
    {
        path:'/admin/users',
        element: <UsersAdmin />,
        authRequired: true,
    },
    {
        path:'/admin/payment-log',
        element: <RecentTransactions />,
        authRequired: true,
    },
    {
        path:'/password-reset/:token',
        element: <PasswordResetPage />,
        authRequired: false,
    },
    {
        path: '/admin/approvals-pending-payment',
        element: <ApprovalsPendingPayment />,
        authRequired: true,
    },

    /**
     * Static Pages
     */
    {
        path:'/about',
        element: <About />,
        authRequired: false,
    },
    {
        path:'/college',
        element: <CollegeStorage />,
        authRequired: false,
    },
    {
        path:'/features',
        element: <Features />,
        authRequired: false,
    },
    {
        path:'/calculator',
        element: <SpaceCalculator />,
        authRequired: false,
    },
    {
        path:'/contact',
        element: <ContactUs />,
        authRequired: false,
    },
    {
        path:'/tips',
        element: <StorageTips />,
        authRequired: false,
    },
    {
        path:'/terms',
        element: <TermsConditions />,
        authRequired: false,
    },
    {
        path:'/privacy',
        element: <PrivacyPolicy />,
        authRequired: false,
    },
    {
        path:'/index',
        element: <Index />,
        authRequired: false,
    }
];

export default AppRoutes;
