import PropTypes from 'prop-types';
import AppUserGenericDTO from '../../../DTOs/User/AppUserGenericDTO';
import {Paper, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

export default function UserDetailPanel(props) {
    const{row} = props;

    const styling = {marginBottom: '20px'};

    return (
        <Stack
            sx={{ py: 2, height: '100%', boxSizing: 'border-box'}}
            direction={'column'}
        >
            <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                <Stack direction={'column'} spacing={1} sx={{ height: 1 }}>
                    <Grid container>
                        <Grid item md={4} xs={4} sx={styling}>
                            <Typography variant={'body2'}>E-mail</Typography>
                            <Typography variant={'body1'}>{row.username}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4} sx={styling}>
                            <Typography variant={'body2'}>Full Name</Typography>
                            <Typography variant={'body1'}>{`${row.firstName} ${row.lastName}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4} sx={styling}>
                            <Typography variant={'body2'}>Phone Number</Typography>
                            <Typography variant={'body1'}>{`${row.phone}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4} sx={styling}>
                            <Typography variant={'body2'}>Door Code</Typography>
                            <Typography variant={'body1'}>{`${row.doorCode ?? ''}`}</Typography>
                        </Grid>
                        <Grid item md={4} sx={styling} xs={4}>
                            <Typography variant={'body2'}>Driver&apos;s License Number</Typography>
                            <Typography variant={'body1'}>{`${row.driversLicenseNumber}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{marginTop: '20px'}}>
                        <Grid item md={4} xs={4} sx={{marginBottom: '20px'}}>
                            <Typography variant={'body2'}>Address 1</Typography>
                            <Typography variant={'body1'}>{`${row.address1 ?? ''}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Address 2</Typography>
                            <Typography variant={'body1'}>{`${row.address2 ?? ''}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>City</Typography>
                            <Typography variant={'body1'}>{`${row.city ?? ''}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>State</Typography>
                            <Typography variant={'body1'}>{`${row.state ?? ''}`}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Typography variant={'body2'}>Zip</Typography>
                            <Typography variant={'body1'}>{`${row.zip ?? '' }`}</Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Paper>
        </Stack>
    );
}

UserDetailPanel.propTypes = {
    row: PropTypes.objectOf(AppUserGenericDTO),
};
