import React, {useEffect, useState} from 'react';
import { Collapse, NavbarToggler, NavLink } from 'reactstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link, useNavigate} from 'react-router-dom';
import './NavMenu.css';
import authenticationManager from '../Auth/AuthenticationManager';
import PropTypes from 'prop-types';

export function NavMenu(props) {
    const {isMobile, isTablet, isDesktop} = props;
    const [collapsed, setCollapsed] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {

    }, [authenticationManager.isUserAuthorized(), isMobile, isTablet, isDesktop]);

    return (
        <header>
            <Navbar className={'navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3' + isDesktop ? ' justify-content-center' : ''} variant={'dark'} bg={'dark'} expand={'lg'} style={{marginBottom: '20px'}}>
                <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse collapse-button" isOpen={!collapsed}>
                    <Container fluid>
                        <ul className={'navbar-nav justify-content-center'} style={{display: collapsed && !isTablet && !isDesktop ? 'none' : 'flex'}}>
                            <Nav>
                                <NavLink className={'navbar-item'} tag={Link} to={'/index'}>Home</NavLink>
                            </Nav>
                            {authenticationManager.isUserAuthorized() ?
                                <>
                                    <Nav>
                                        <NavLink className={'navbar-item'} tag={Link} to={'/user-info'}>Your Account Info</NavLink>
                                    </Nav>
                                    <Nav>
                                        <NavLink className={'navbar-item'} tag={Link} to={'/user-storage-units'}>Change Payment Method</NavLink>
                                    </Nav>
                                    <Nav>
                                        <NavDropdown
                                            title={'Storage Units'}
                                            menuVariant={'dark'}
                                        >
                                            <NavDropdown.Item onClick={() => navigate('/user-storage-units')}>Your Storage Units</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => navigate('/available-units')}>Available Storage Units</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav>
                                    { (authenticationManager.isAdmin() || authenticationManager.isSuperAdmin()) &&
                        <Nav>
                            <NavDropdown
                                title={'Admin'}
                                menuVariant={'dark'}
                            >
                                <NavDropdown.Item onClick={() => navigate('/admin/storage-unit-maintenance')}>Storage Unit Maintenance</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/admin/pending-approvals')}>Pending Approvals</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/admin/current-subscriptions')}>Current Subscriptions</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/admin/hold-storage-units')}>Storage Unit Holds</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/admin/users')}>Users</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/admin/payment-log')}>Recent Transactions</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/admin/approvals-pending-payment')}>Approvals Pending Payment</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                                    }
                                </>
                                :
                                <>
                                    <Nav>
                                        <NavLink className={'navbar-item'} tag={Link} to={'/register'}>Register</NavLink>
                                    </Nav>
                                    <Nav>
                                        <NavLink className={'navbar-item'} tag={Link} to={'/'}>Login</NavLink>
                                    </Nav>
                                </>
                            }
                            <Nav>
                                <NavLink className={'navbar-item'} tag={Link} to={'/features'}>Features</NavLink>
                            </Nav>
                            <Nav>
                                <NavDropdown
                                    title={'More Info'}
                                    menuVariant={'dark'}
                                >
                                    <NavDropdown.Item onClick={() => navigate('/about')}>About Us</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => navigate('/college')}>College Storage</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Nav>
                                <NavLink className={'navbar-item'} tag={Link} to={'/calculator'}>Space Calculator</NavLink>
                            </Nav>
                            <Nav>
                                <NavLink className={'navbar-item'} tag={Link} to={'/contact'}>Contact Us</NavLink>
                            </Nav>
                            {authenticationManager.isAuthorized &&
                                <Nav>
                                    <NavLink className={'navbar-item'} tag={Link} to={'/logout'}>Logout</NavLink>
                                </Nav>
                            }

                        </ul>

                    </Container>
                </Collapse>
            </Navbar>
        </header>
    );
}

NavMenu.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired,
};
