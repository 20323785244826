import React, {useEffect} from 'react';

export default function StorageTips() {
    const collegeBanner = require('../../resources/css/images/college-banner.jpg');

    useEffect(() => {
        document.title = 'Executive Storage Suites - Storage Tips';
    }, []);
    return (
        <div id="page-wrapper">
            <section className="wrapper style1">
                <div className="container">
                    <div id="content">

                        <article>
                            <header>
                                <h2>Executive Storage Suites Tips</h2>
                            </header>

                            <span className="image featured"><img src={String(collegeBanner)} alt="" /></span>

                            <p>At Executive Storage Suites, we want to ensure that you have everything you need for convenient and efficient storage in Wisconsin. When packing and storing your items, there are certain steps that you can take in order to keep your belongings from being damaged or harmed. For a better storage experience with us here, browse the following list of helpful storage tips.</p>
                            <ul>
                                <li>Determine whether the goods you are storing will be better protected by placing them in boxes, totes, or other containers.  You may need to purchase specialized boxes and other packing supplies to protect your items during the move and while they are stowed away.</li>
                                <li>Thoroughly clean, disinfect and dry refrigerators, freezers, stoves and other appliances. Appliances with doors can be stored with the door slightly open, and some can be stacked on top of each other.</li>
                                <li>Be sure to clean and dry all electrical equipment before storing in your unit.</li>
                                <li>Retard rust by wiping down all types of metal surfaces before storing. Use a few drops of machine oil to lightly coat these items for preventing rust and possible deterioration in the storage unit.</li>
                                <li>Pack all books, files, and documents flat, and use bubble wrap or newspaper to fill any empty spaces in the boxes or totes.</li>
                                <li>Store clothing on hangars before folding them into cartons. Make sure all drapes, curtains, linens and clothing items are washed and dry, or dry cleaned to avoid attracting pests.</li>
                                <li>Use bubble wrap and other packing materials when storing dishes and glassware. Pack similar items in the same box and fill empty spaces with packing peanuts, bubble wrap or newspaper.</li>
                                <li>Disassemble furniture and place pieces on a corrugated cardboard mat or plastic sheet before placing on a pallet.</li>
                                <li>Stack lightweight chairs “seat to seat”, but avoid stacking other types of furniture on top of each other. Cover all upholstery with light cotton dust covers.</li>
                                <li>Wrap lamp bases in padding and store the lamp shade and base separately.</li>
                                <li>Holiday decorations can be stored in their original cartons. Wrap glass ornament and other delicate items in bubble wrap or newspaper before storing in boxes or totes.</li>
                                <li>CDs, tapes, records and other music items can be stored in boxes or airtight cartons to prevent damage.</li>
                                <li>Cover computer screens and large televisions screens with dust covers and wrap in bubble wrap if you are storing these items next to furniture and other equipment.</li>
                                <li>Bring plenty of moving and packing supplies to safely store your belongings.</li>
                            </ul>

                            <b>Prohibited Storage Goods</b><br/>
                            Even though you alone control access to your storage space, there are some items that cannot be stored in your unit at any time. These include:
                            <ul>

                                <li>Live animals</li>
                                <li>Perishables</li>
                                <li>Liquids</li>
                                <li>Explosives</li>
                                <li>Flammable liquids and fuels</li>
                                <li>Toxic materials</li>
                                <li>Any items that need to be in a controlled environment</li>
                            </ul>

                            <p><b>Sources: Self Storage Association (SSA)</b></p>

                        </article>
                    </div>
                </div>
            </section>
        </div>
    );
}
