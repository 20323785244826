import React from 'react';
import PropTypes from 'prop-types';

export default function TextInput(props) {


    return(
        <>
            {props.label && !props.errorMessage && (<label>{props.label}</label>)}
            {props.errorMessage && (<label className={'errorMessage'}>{props.errorMessage}</label>)}
            {props.isTextArea ?
                (
                    <textarea
                        id={props.id}
                        name={props.name}
                        onChange={props.handleChange}
                        value={props.value}
                        rows={props.rows ?? 3}
                        placeholder={props.placeholder}
                    />
                ):
                (
                    <input
                        type={'text'}
                        id={props.id}
                        name={props.name}
                        placeholder={props.placeholder}
                        onChange={props.handleChange}
                        value={props.value}
                    />)
            }
        </>
    );
}

TextInput.propTypes = {
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    isTextArea: PropTypes.bool,
    value: PropTypes.string,
    rows: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
};
