import apiService from './ApiService';

export default class SubscriptionApiService {

    /**
     * Get subscriptions for user
     * @return {Promise<ServiceResponse>}
     * @constructor
     */
    async getSubscriptionsForUser() {
        const subscriptions = await apiService.getData('/api/Subscription');

        return subscriptions.data;
    }

    async cancelSubscription(selectedSubscription) {
        const response = await apiService.postData('/api/Subscription/cancel/' + selectedSubscription.id, null);

        return response.data;
    }

    /**
     * Get a user's payment methods
     * @param userId {number}
     * @returns {Promise<ServiceResponse<Array<PaymentMethodDTO>>>}
     */
    async getUserPaymentMethods(userId) {
        const response = await apiService.getData('/api/Subscription/user-payment-methods/' + userId);

        return response.data;
    }

    /**
     * Saves Payment Method being used on a subscription
     * @param subscriptionId {number}
     * @param paymentProfileId {string}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async updatePaymentMethod(subscriptionId, paymentProfileId) {
        const response = await apiService.putData('/api/Subscription/update-payment-method/' + subscriptionId + '/payment-profile/' + paymentProfileId, null);

        return response.data;
    }

    /**
     * Add Payment Method
     * @param subscriptionId {number}
     * @param cardInfo {CardPaymentDto}
     * @return {Promise<ServiceResponse<PaymentMethodDTO>>}
     */
    async addPaymentMethod(subscriptionId, cardInfo) {
        const response = await apiService.postData('/api/Subscription/add-payment-method/' + subscriptionId, cardInfo);

        return response.data;
    }

    /**
     * Get all pending approvals
     * @return {Promise<ServiceResponse<List<PendingApprovalDTO>>>}
     */
    async getPendingApprovals() {
        const response = await apiService.getData('/api/Subscription/admin/pending-approvals');

        return response.data;
    }

    /**
     * Approve a pending approval
     * @param pendingApprovalId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async approvePendingApproval(pendingApprovalId) {
        const response = await apiService.postData('api/Subscription/admin/pending-approvals/approve/' + pendingApprovalId, null);

        return response.data;
    }

    /**
     * Deny a pending approval
     * @param pendingApprovalId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async denyPendingApproval(pendingApprovalId) {
        const response = await apiService.postData('api/Subscription/admin/pending-approvals/deny/' + pendingApprovalId, null);

        return response.data;
    }

    /**
     * Gets all subscriptions
     * @return {Promise<ServiceResponse<Array<SubscriptionDTO>>>}
     */
    async getAllSubscriptions() {
        const response = await apiService.getData('api/Subscription/admin');

        return response.data;
    }

    /**
     * Cancel a subscription
     * @param subscriptionId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async forceDeleteSubscription(subscriptionId) {
        const response = await apiService.deleteData(`api/Subscription/admin/${subscriptionId}`, null);

        return response.data;
    }

    /**
     * Get Approvals pending payment
     * @return {Promise<ServiceResponse<Array<>>>}
     */
    async getApprovalsPendingPayment() {
        const response = await apiService.getData('api/Subscription/admin/approvals-pending-payment');

        return response.data;
    }

    /**
     * Revoke an approval
     * @param token {string}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async revokeApproval(token) {
        const response = await apiService.deleteData(`api/Subscription/admin/approvals-pending-payment/${token}`);

        return response.data;
    }
}
